import React, { useContext, useState, useEffect } from "react";
import { makeStyles, Typography, Grid, MenuItem, Box, IconButton, InputAdornment } from "@material-ui/core";
import MROTextField from "components/form_components/TextField";
import QuantityCounter from "components/quantity_counter";
import WarningIcon from "@material-ui/icons/Warning";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import MROButton from "components/buttons";
import { OperationService } from "lib/services/api/operaitons/save_operation";
import { ToastMessageContext } from "lib/contexts/message_context";
import PrimePartsClientServices from "lib/services/api/stores/tooling/parts_client";
import CopyParts from 'assets/copyparts.png'
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      minHeight: "89px",
    },

    "& textarea::placeholder": {
      // textTransform: "none !important",
    },
  },

  title: {
    fontSize: "20px",
  },
}));

function CreateNew(props) {
  const { onClose, workOrderId, baseStationId, lineCustomerId, acReg, workOrderNum, taskDetails } = props;

  const classes = useStyles();

  const message = useContext(ToastMessageContext);

  const [unitQty, setUnitQty] = useState(1);
  const [requiredQty, setRequiredQty] = useState();
  const [value, setValue] = useState("no");
  const [errors, setErrors] = useState({});
  const [busy, setBusy] = useState(null);
  const [workOrderNumber, setWorkOrderNum] = useState(null);
  const [unitOfMeasureItems, setUnitOfMeasureItems] = useState([]);
  const [quantityRemoved, setQuantityRemoved] = useState();
  const [copyPartsOff, setCopyPartsOff] = useState('')
  const [copyPartsON, setCopyPartsOn] = useState('')
  const [description, setDescription] = useState('')
  const [maintdata, setMaintData] = useState('')
  const [serialOff, setSerialOff] = useState('')
  const [defferal, setdefferal] = useState('')
  const [location, setLocation] = useState('')
  const [comments, setComments] = useState('')
  const [seletectedTask, setSelectedTask] = useState({})
  const [selectedTaskId, SetSelectedID] = useState({})



  const user = JSON.parse(sessionStorage.getItem("user"));
  useEffect(() => {
    const myData = JSON.parse(localStorage.getItem("workOrder"));
    setWorkOrderNum(myData?.work_order_number);
  }, []);

  useEffect(() => {
    PrimePartsClientServices.fetchDependencies()
      .then((res) => {
        if (res) {
          setUnitOfMeasureItems(res.units_of_measure);
        }
      })
      .catch((err) => {
        console.log("something weing wrong!", err);
      });
  }, []);
  const handleCopyFunction = () => {
    setCopyPartsOff(copyPartsON)

  }

  const unkonwnPartFields = [
    {
      label: "P/N *",
      placeholder: "Part Number",
      type: "text",
      required: true,
      name: "part_number",
      value: copyPartsON,
      setValue: setCopyPartsOn
    },
    {
      label: "Description *",
      placeholder: "Max 30 characters",
      type: "text",
      required: true,
      name: "description",
      value: description,
      setValue: setDescription
    },
    {
      label: "Units of Measure *",
      placeholder: "",
      defaultValue: 1,
      type: "dropdown",
      values: unitOfMeasureItems?.map((item) => ({
        id: item?.units_of_measure_id,
        value: item?.unit_name,
      })),
      required: true,
      name: "units_of_measure_id",
    },
    // {
    //   label: "Units Issued Each QTY *",
    //   placeholder: "QTY",
    //   type: "qty",
    //   required: true,
    //   name: "units_issued",
    //   qty: unitQty,
    //   setQty: setUnitQty,
    //   shouldNotAcceptZero: true,
    // },
    {
      label: "Maint Data Ref *",
      placeholder: "AMM, CMM, IPC etc reference",
      type: "text",
      required: true,

      name: "maint_data_ref",
      value: maintdata,
      setValue: setMaintData
    },
  ];

  const addPartFields = [

    {
      label: "QTY Required *",
      placeholder: "QTY",
      type: "qty",
      required: true,
      name: "required_quantity",
      qty: requiredQty,
      setQty: setRequiredQty,
      shouldNotAcceptZero: true,
    },
    {
      label: "Task # *",
      placeholder: "Search & Select",
      type: "auto",
      required: true,
      name: "operational_task_id",
      setId: SetSelectedID,
      value: seletectedTask,
      setValue: setSelectedTask,
      values: taskDetails?.map((item) => ({
        id: item?.id,
        value: item?.task_tlp,
      })),
    },

    {
      label: "Comment *",
      placeholder: "E.g., ZONE",
      type: "text",
      required: true,
      name: "issued_comment",
      value: comments,
      setValue: setComments,

    }, {
      label: "DD / HIL # ",
      placeholder: "DD / HIL reference",
      type: "text",
      required: false,
      name: "add_deferral",
      value: defferal,
      setValue: setdefferal
    },
  ];

  const repairableFields = [
    {
      label: "Part # Off *",
      placeholder: "Enter Part Number",
      type: "text",
      required: true,
      name: "part_off",
      value: copyPartsOff,
      setValue: setCopyPartsOff
    },
    {
      label: "Serial # Off *",
      placeholder: "Enter Serial Number",
      type: "text",
      required: true,
      name: "serial_off",
      value: serialOff,
      setValue: setSerialOff
    },
    {
      label: "QTY Removed *",
      placeholder: "Quantity",
      type: "qty",
      required: true,
      name: "removed_quantity",
      qty: quantityRemoved,
      setQty: setQuantityRemoved,
      shouldNotAcceptZero: true,
    },
    {
      label: "U/S Comment ",
      placeholder:
        "Enter a removal reason or other comment as applicable - Max 150 Characters",
      type: "text",
      required: false,
      multiline: true,
      name: "us_comment",
      value: comments,
      setValue: setComments
    },
  ];

  // fields validation
  const validateFields = (formData) => {
    let error = {};
    let valid = true;

    const requiredQty = formData.get("required_quantity");
    const units_issued_qty = formData.get("units_issued");
    const removedQty = formData.get("removed_quantity");

    for (let pair of formData.entries()) {
      const key = pair[0];
      console.log(pair);
      if (pair[1] === "" && key !== "comment" && key !== "add_deferral") {
        error[key] = true;
        valid = false;
      }
    }

    if (+requiredQty === 0 && requiredQty !== "") {
      error["required_quantity_is_zero"] = true;
      error["required_quantity"] = true;
      valid = false;
    }
    if (+removedQty === 0 && removedQty !== "" && value === "yes") {
      error["removed_quantity_is_zero"] = true;
      error["removed_quantity"] = true;
      valid = false;
    }
    if (+units_issued_qty === 0 && units_issued_qty !== "") {
      error["units_issued_qty_is_zero"] = true;
      error["units_issued"] = true;
      valid = false;
    }

    if (!valid) {
      setErrors(error);
    }
    console.log({ error });
    return valid;
  };

  const createJobReq = async (formData, id) => {
    const part_off = formData.get("part_off");
    const serial_off = formData.get("serial_off");
    const defferal_data = formData.get("add_deferral");
    const issued_comment = formData.get("issued_comment");
    const install_location = formData.get("location");
    const quantity = formData.get("required_quantity");
    const task_id = formData.get("operational_task_id")
    const params = {
      primary_part_id: id,
      reportable_type: "WorkOrder",
      reportable_id: workOrderId,
      quantity,
      to_base_station_id: baseStationId,
      install_location,
      rotable_removed: value === "yes" ? true : false,
      part_off,
      serial_off,
      issued_comment,
      line_customer_id: lineCustomerId,
      aircraft_registration: acReg,
      user_id: user.id,
      defferal_data,
      order_number: workOrderNumber,
      operational_task_id: task_id

    };


    await OperationService.createJobRequest(params).then((response) => {
      if (response.success) {

      }
      else {
        message.showToastMessage({ message: response.errors ? response.errors.join(', ') : "Something went wrong. Try again", variant: "error" });

      }
      onClose();
    }).
      catch((res) => {
        message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again", variant: "error" });
      })
  };

  // form submit handler
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const part_number = formData.get("part_number").toUpperCase();

    formData.set("part_number", part_number);
    formData.append("user_id", user.id);
    formData.append("units_issued", "1")

    if (!validateFields(formData)) {
      return;
    }
    formData.delete("operational_task_id")
    formData.append("operational_task_id", selectedTaskId?.id)
    try {
      setBusy("loading");
      const response = await OperationService.addPart(formData);

      setBusy(null);
      if (response.success) {
        message.showToastMessage({
          message: "Added successfully.",
          variant: "success",
        });
        createJobReq(formData, response.id);
      } else {
        const msg =
          response.errors?.length > 0
            ? response.errors.join(", ")
            : "Something went wrong!";
        message.showToastMessage({
          message: msg,
          variant: "error",
        });
      }
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong!",
        variant: "error",
      });
      setBusy("error");
    }
  };

  const fields = ({
    placeholder,
    label,
    name,
    multiline,
    type,
    qty,
    setQty,
    values,
    required,
    defaultValue,
    shouldNotAcceptZero,
    value,
    setValue,
    setId
  }) => {
    switch (type) {
      case "text":
        return (
          <MROTextField
            placeholder={placeholder}
            label={label}
            variant="outlined"
            color="primary"
            InputLabelProps={{ shrink: true }}
            style={{ marginRight: "50px" }}
            inputProps={{
              maxLength:
                name === "part_number" ||
                  name === "description" ||
                  name === "maint_data_ref" ||
                  name === "part_off" || name === "issued_comment"
                  ? 30
                  : undefined,
              style: { textTransform: name === "part_number" && "uppercase" },
            }}
            InputProps={{

              endAdornment: name === "part_off" ? <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleCopyFunction}
                  edge="end"
                >
                  <img src={CopyParts} height={'22px'} />
                </IconButton>
              </InputAdornment> : '',
            }}
            name={name}
            multiline={multiline}
            autoFocus={errors[name]}
            error={errors[name]}
            helperText={
              errors[name] &&
              required &&
              `Please select ${label.replace("*", "")}`
            }
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              setErrors((prevState) => ({ ...prevState, [name]: false }))
            }
            }
          />
        );
      case "select":
        return (
          <MROTextField
            placeholder={placeholder}
            label={label}
            variant="outlined"
            color="primary"
            InputLabelProps={{ shrink: true }}
            style={{ marginRight: "50px" }}
            select
            name={name}
            multiline={multiline}
            autoFocus={errors[name]}
            error={errors[name]}
            helperText={
              errors[name] &&
              required &&
              `Please select ${label.replace("*", "")}`
            }
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              setErrors((prevState) => ({ ...prevState, [name]: false }))
            }
            }
          />
        );
      case "qty":
        const fieldName = label.replace("*", "");
        const errMsg =
          (errors?.required_quantity_is_zero || errors?.units_issued_qty_is_zero || errors?.removed_quantity_is_zero) && (name === "required_quantity" || name === "units_issued" || name === "removed_quantity")
            ? `${fieldName} must be greater than 0`
            : `Please Enter ${fieldName}`;
        return (
          <QuantityCounter
            name={name}
            label={label}
            placeholder={placeholder}
            setQty={setQty}
            qty={qty}
            autoFocus={errors[name]}
            error={errors[name]}
            shouldNotAcceptZero={shouldNotAcceptZero}
            helperText={errors[name] && required && errMsg}
            setError={() =>
              setErrors((prevState) => ({ ...prevState, [name]: false }))
            }
            minQty={1}
          />
        );

      case "dropdown":
        return (
          <MROTextField
            variant="outlined"
            color="primary"
            label={label}
            InputLabelProps={{ shrink: true }}
            style={{ marginRight: "50px" }}
            name={name}
            select
            defaultValue={defaultValue}
            placeholder={placeholder}
            // value={'each'}
            // displayEmpty
            autoFocus={errors[name]}
            error={errors[name]}
            helperText={
              errors[name] &&
              required &&
              `Please select ${label.replace("*", "")}`
            }
            onChange={() =>
              setErrors((prevState) => ({ ...prevState, [name]: false }))
            }
          >
            {placeholder !== "" && (
              <MenuItem value={placeholder}>{placeholder}</MenuItem>
            )}
            {values?.map((item, i) => (
              <MenuItem key={item.id} value={item.id}>
                {item.value}
              </MenuItem>
            ))}
          </MROTextField>
        );
      case "auto":

        return (
          <Autocomplete
            freeSolo
            fullWidth
            getOptionSelected={(option, value) => option.id === value.id}
            filterSelectedOptions={true}
            onChange={(event, value) => {
              setId(value)
            }}
            id="tags-id"
            options={values || []}
            defaultValue={defaultValue}
            getOptionLabel={(option) => option.value || ""}
            onInputChange={(event, newInputValue) => {
              setValue(newInputValue)

            }}
            inputValue={value}
            renderInput={(params) => (
              <MROTextField
                {...params}
                // variant="standard"
                label={label}
                InputLabelProps={{ shrink: true }}
                style={{ marginRight: "50px" }}
                name={name}
                color={"primary"}
                placeholder={"Search & Select"}
                error={errors[name]}
                helperText={
                  errors[name] &&
                  required &&
                  `Please select ${label.replace("*", "")}`
                }

                onSelect={() => {
                  setErrors((prevState) => ({ ...prevState, [name]: false }))
                }}
              />
            )}
          />



        )


      default:
        break;
    }
  };

  const handleToggle = (value) => {
    if (value) {
      setValue(value);
    }
  };

  return (
    <div className={classes.root}>
      <form noValidate onSubmit={submitHandler}>
        <Typography color="primary" className={classes.title}>
          Unknown Part
        </Typography>
        <Grid container>
          <Grid item container xs={10}>
            {unkonwnPartFields.map((field, i) => (
              <Grid item key={i} xs={6} style={{ paddingRight: "40px" }}>
                {fields(field)}
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Typography
          color="primary"
          className={classes.title}
          style={{ marginTop: "20px", marginBottom: "13px" }}
        >
          Add Part
        </Typography>

        <Grid container>
          <Grid item container xs={10}>
            {addPartFields.map((field, i) => (
              <Grid item key={i} xs={12} md={6} style={{ paddingRight: "40px" }}>
                {fields(field)}
                {/* {field.type === "qty" && (
                  <Box display="flex" alignItems="center">
                    <WarningIcon style={{ color: "#FFB300" }} fontSize="20px" />
                    <p style={{ color: "#4D4F5C", marginLeft: "15px" }}>
                      Required QTY exceeds Base Stock QTY! - Please enter a
                      deferred defect number for tracking
                    </p>
                  </Box>
                )} */}
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* 
        <Box mb={1} mt="26px">
          <Box display="inline-block" mr={2}>
            <Typography gutterBottom={12} style={{ fontWeight: "bold" }}>
              Repairable Part Removed? *
            </Typography>
          </Box>
          <input
            style={{ display: "none" }}
            name="part_removed"
            value={value === "yes" ? true : false}
          />
          <ToggleButtonGroup
            value={value}
            name=""
            exclusive
            onChange={(e, val) => handleToggle(val)}
            aria-label="yes-or-no"
          >
            <ToggleButton
              className={
                errors.part_removed && !errors?.part_removed?.valid
                  ? classes.error
                  : {}
              }
              size={"small"}
              value="no"
              aria-label="centered"
            >
              No
            </ToggleButton>
            <ToggleButton
              className={
                errors.part_removed && !errors?.part_removed?.valid
                  ? classes.error
                  : {}
              }
              size={"small"}
              value="yes"
              aria-label="left aligned"
            >
              Yes
            </ToggleButton>
          </ToggleButtonGroup>
        </Box> */}

        {/* {value === "yes" && (
          <Grid container>
            <Grid item container xs={5}>
              {repairableFields.map((field, i) => (
                <Grid item key={i} xs={12} style={{ paddingRight: "40px" }}>
                  {fields(field)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )} */}

        <Grid item container xs={12} justify="flex-end" style={{ marginTop: "20px" }}>
          <MROButton
            style={{ marginRight: "20px" }}
            type="button"
            variant={"contained"}
            onClick={onClose}
          >
            Cancel
          </MROButton>
          <MROButton
            type="submit"
            variant={"contained"}
            // style={{ backgroundColor: "#FFB300" }}
            color="primary"
            loading={busy === "loading"}
          >
            Add
          </MROButton>
        </Grid>
      </form>
    </div>
  );
}

export default CreateNew;
