import React, { useEffect, useReducer, useState } from 'react'
import { Paper, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import UserService from '../../lib/services/api/admin/users/user_client';
import authReducer from '../../reducers/auth_reducer';
import { INITIAL_STATE } from '../../reducers/admin/line/work_order_builder_reducer';
import AuthActions from '../../actions/auth_actions';
import LineManagerUsers from './LineManagerUsers';
import moment from 'moment';
//import all tab menu
import { topNavMenuStores, topNavMenuPlanning, topNavMenuProcurement, topNavMenuCompliance, topNavMenuAccounts, topNavMenuAdmin, topNavMenuDashboards, topNavMenuOperations, topNavMenuSMS } from './allTabMenu';
import LeaveRequest from './LeaveRequest';

export const useStyles = makeStyles(theme => ({
  alertWrapper: {
    width: '100%',
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightRegular
  },
  amberAlert: {
    color: '#FFB300',
    backgroundColor: `rgba(255, 179, 0, 0.15)`
  },
  errorAlert: {
    color: '#DF0011',
    backgroundColor: `rgba(223, 0, 17,0.15)`
  },
  successAlert: {
    color: '#4FC605',
    backgroundColor: `rgba(79,198,5, 0.15)`
  }
}))

export default function Home(props) {

  const classes = useStyles();
  const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE);
  const [user, setUser] = useState([]);
  const [messageState, setMessageState] = useState("");
  const [lmUser, setLmUser] = useState(false);
  const [lastLogin, setLastlogin] = useState('')
  useEffect(() => {
    AuthActions.setUserData(dispatch, sessionStorage.getItem('authState'))
  }, [])

  useEffect(() => {
    if (authState?.authState?.user?.id) {
      // console.log(authState.authState?.user)
      const user = authState.authState.user;
      UserService.getUserMe(authState?.authState?.user?.id).then(res => {
        setUser(res.data)
      })
      setLmUser(user)

    }
  }, [authState && authState?.authState?.user]);


  useEffect(() => {
    if (user.length > 0) {
      let testDateUtc = moment.utc(user[0]?.last_sign_in_at, 'YYYY-MM-DD, HH:mm:ss');
      let localDate = moment(testDateUtc).local().format('DD/MM/YYYY, HH:mm')
      let finalDate = localDate === "Invalid date" ? '' : localDate
      setLastlogin(finalDate)
      // console.log("entert=============")
    }
  }, [user?.length])

  useEffect(() => {
    switch (user[0]?.status) {
      case 'Expired':
        return setMessageState('error')
      case 'Good':
        return setMessageState('success')
      case 'Low':
        return setMessageState('amber')
      default:
        break;
    }
  }, [user])

  const messageAlert = `${messageState}Alert`

  const Section = ['Operations', 'Planning', 'Dashboard', 'Stores', 'Procurement', 'Compliance', 'Accounts', 'Admin'];

  const [topMenus, setTopMenus] = useState({
    Operations: [],
    Planning: [],
    Dashboard: [],
    Stores: [],
    Procurement: [],
    Compliance: [],
    Accounts: [],
    Admin: []
  });

  const [permissions, setPermissions] = useState({
    Operations: [],
    Planning: [],
    Dashboard: [],
    Stores: [],
    Procurement: [],
    Compliance: [],
    Accounts: [],
    Admin: [],
    SMS: []
  });
  const [combinedState, setCombinedState] = useState({});

  useEffect(() => {
    setCombinedState({
      operationsCat: authState?.authState?.categories?.find(category => category.name === 'Operations')?.sub_categories,
      planningCat: authState?.authState?.categories?.find(category => category.name === 'Planning')?.sub_categories,
      dashboardCat: authState?.authState?.categories?.find(category => category.name === 'Dashboard')?.sub_categories,
      storesCat: authState?.authState?.categories?.find(category => category.name === 'Stores')?.sub_categories,
      procurementCat: authState?.authState?.categories?.find(category => category.name === 'Procurement')?.sub_categories,
      complianceCat: authState?.authState?.categories?.find(category => category.name === 'Compliance')?.sub_categories,
      accountsCat: authState?.authState?.categories?.find(category => category.name === 'Accounts')?.sub_categories,
      adminCat: authState?.authState?.categories?.find(category => category.name === 'Admin')?.sub_categories,
      smsCat: authState?.authState?.categories?.find(category => category.name === 'SMS')?.sub_categories,
    });
  }, [authState?.authState?.categories])

  useEffect(() => {
    if (combinedState) {
      const sections = {
        Operations: topNavMenuOperations,
        Planning: topNavMenuPlanning,
        Dashboard: topNavMenuDashboards,
        Stores: topNavMenuStores,
        Procurement: topNavMenuProcurement,
        Compliance: topNavMenuCompliance,
        Accounts: topNavMenuAccounts,
        Admin: topNavMenuAdmin,
        SMS: topNavMenuSMS
      };

      const newTopMenus = {};
      const newPermissions = {};

      Object.keys(sections).forEach(section => {
        const cat = combinedState[section.toLowerCase() + 'Cat'];

        if (cat) {
          newTopMenus[section] = sections[section].filter(s => cat.some(c => c.name === s.name));
          newPermissions[section] = cat.map(c => authState.authState.permissions.find(p => p.source_id === c.id));
        }
      });

      setTopMenus({ ...topMenus, ...newTopMenus });
      setPermissions({ ...permissions, ...newPermissions });
    }
  }, [combinedState]);

  const filterTopMenus = (categories, topMenus, permissionData) => {
    return topMenus.filter(section => {
      return categories?.some(category => {
        const permission = permissionData.find(p => p.source_id === category.id);
        return permission && (permission.read || permission.write) ? category.name === section.name : false;
      })
    });
  }

  useEffect(() => {
    if (combinedState) {
      setTopMenus((prevState) => ({
        ...prevState,
        Operations: filterTopMenus(combinedState.operationsCat, topNavMenuOperations, permissions.Operations),
        Planning: filterTopMenus(combinedState.planningCat, topNavMenuPlanning, permissions.Planning),
        Dashboard: filterTopMenus(combinedState.dashboardCat, topNavMenuDashboards, permissions.Dashboard),
        Stores: filterTopMenus(combinedState.storesCat, topNavMenuStores, permissions.Stores),
        Procurement: filterTopMenus(combinedState.procurementCat, topNavMenuProcurement, permissions.Procurement),
        Compliance: filterTopMenus(combinedState.complianceCat, topNavMenuCompliance, permissions.Compliance),
        SMS: filterTopMenus(combinedState.smsCat, topNavMenuSMS, permissions.SMS),
        Accounts: filterTopMenus(combinedState.accountsCat, topNavMenuAccounts, permissions.Accounts),
        Admin: filterTopMenus(combinedState.adminCat, topNavMenuAdmin, permissions.Admin)
      }));
    }
  }, [combinedState, permissions])


  const { Accounts, Admin, Compliance, Dashboard, Operations, Planning, Procurement, Stores, SMS } = topMenus;

  // console.log("topmenues are===============================", Accounts, Admin)
  const combinedArr = { Accounts, Admin, Compliance, Dashboard, Operations, Planning, Procurement, Stores, SMS }
  useEffect(() => {
    sessionStorage.setItem(
      "RouterSetup",
      JSON.stringify(combinedArr)
    );
  }, [topMenus]);


  return (
    <>
      <Box p={5}>
        <Paper elevation={0}>
          <Box height="max-content" p={3}>
            <Typography variant="h4" color="primary">Hello {user[0]?.user_name}</Typography>
            <Box my={2}>
              <img width={120} height={120} style={{ borderRadius: '50%' }} src={user[0]?.profile_img ? user[0]?.profile_img : "https://cdn4.iconfinder.com/data/icons/glyphs/24/icons_user2-128.png"} alt="profile-image" />
            </Box>
            <Box mt={1} >
              <Typography style={{ color: '#767676', fontSize: '11px', fontWeight: 600 }}>Last Login: {lastLogin} </Typography>
            </Box>
            <Box mt={1} >
              <Typography variant="h6" color="primary">Your compliance status is</Typography>
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
              <Alert className={clsx(classes.alertWrapper, classes[messageAlert])} icon={false} severity={messageState} color={messageState}>
                {user[0]?.message}
              </Alert>
            </Box>
            {lmUser.is_line_manager && <LineManagerUsers messageState={messageState} setMessageState={setMessageState} user={lmUser} />}
            <Box my={2}>
              <LeaveRequest />
            </Box>

          </Box>
        </Paper>
      </Box>
    </>
  )
}
