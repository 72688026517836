import requestrApi from "../../common/boxr_api_client";

const createEndPoints = {
  general_info: "/line_customers/create_line_customers",
  commercials: "/line_customers/create_commercial_rates",
};

function getLineCustomers(params) {
  return requestrApi({
    url: "/line_customers/listing_line_customers",
    method: "GET",
    params,
  });
}

function getGeneralInfo(params) {
  return requestrApi({
    url: "/line_customers/line_customer_details",
    method: "GET",
    params: params,
  });
}
function getTaskDetails(params) {
  return requestrApi({
    url: "/part_requests/listing_task_dependencies",
    method: "GET",
    params: params,
  });
}

function getContactDetails(params) {
  return requestrApi({
    url: "/line_customers/line_customer_contact_details",
    method: "GET",
    params: params,
  });
}

function create(formData, section) {
  return requestrApi({
    url: createEndPoints[section],
    method: "POST",
    data: formData,
  });
}

function updateLineCustomerDetails(formData) {
  return requestrApi({
    url: "/line_customers/update_line_customers",
    method: "PUT",
    data: formData,
  });
}

function checkMandatories(formData) {
  return requestrApi({
    url: `/line_customers/check_mandatories?line_customer_id=${formData}`,
    method: "GET",
  });
}

// work order
function fetchWorkOrderDependencies(lineCustomerId) {
  return requestrApi({
    url: `/work_orders/fetch_work_order_dependencies?line_customer_id=${lineCustomerId}`,
    method: "GET",
  });
}

function createWorkOrder(formData) {
  return requestrApi({
    url: "/work_orders",
    method: "POST",
    data: formData,
  });
}

function updateWorkOrder(formData) {
  return requestrApi({
    url: "/work_orders/update_work_order",
    method: "PUT",
    data: formData,
  });
}
function getLineCustomerDetails(params) {
  return requestrApi({
    url: "/line_customers/line_customer_details",
    method: "GET",
    params,
  });
}

// Delay
function createDelay(formData) {
  return requestrApi({
    url: "/delays",
    method: "POST",
    data: formData,
  });
}

function fetchDelayDependencies(params) {
  return requestrApi({
    url: `/delays/fetch_delay_dependencies`,
    method: "GET",
    params,
  });
}

// Callouts
function createCallOuts(formData) {
  return requestrApi({
    url: "/call_outs",
    method: "POST",
    data: formData,
  });
}

function fetchCallOutsDependencies(params) {
  return requestrApi({
    url: `/call_outs/fetch_call_out_dependencies`,
    method: "GET",
    params,
  });
}
function fetchBases(params) {
  return requestrApi({
    url: `/line_customers/show_all_base`,
    method: "GET",
    params,
  });
}

function createSelectedBases(formData) {
  return requestrApi({
    url: "/line_customers/create_base",
    method: "POST",
    data: formData,
  });

}
const LineCustomerServiceNew = {
  create,
  getLineCustomers,
  getGeneralInfo,
  updateLineCustomerDetails,
  getContactDetails,
  checkMandatories,
  fetchWorkOrderDependencies,
  createWorkOrder,
  updateWorkOrder,
  getLineCustomerDetails,
  createDelay,
  createCallOuts,
  fetchDelayDependencies,
  fetchCallOutsDependencies,
  fetchBases,
  createSelectedBases,
  getTaskDetails
};

export default LineCustomerServiceNew;
