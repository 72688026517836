import React, { useContext, useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import { Box, FormControl, IconButton, InputBase, Menu, MenuItem, Select, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MaterialTable from 'material-table';
import tableReducer, { INITIAL_TABLE_STATE } from '../../../../reducers/table_reducer';
import { ToastMessageContext } from '../../../../lib/contexts/message_context';
import tableIcons, { options } from '../../../../components/universal/table_attributes';
// import DataServiceAction from '../../../../actions/data_actions';
import TableAction from '../../../../actions/table_actions';
import { BASE_API_URL_BOXR } from '../../../../lib/constants';
import ManageDataDialog from '../../../../components/form_components/manage_data_dialog';
import { useRef } from 'react';
import { OperationService } from 'lib/services/api/operaitons/save_operation';
import { format24HrDateOnly } from 'lib/utils/common_utils';
import SearchBarWithFilters from 'components/search_with_filters';
import MROButton from 'components/buttons';
import useDebounce from 'lib/utils/debounce';


const options1 = [

    'Show Certificate',
];

const options2 = [

    'No Certificate'
];

const ITEM_HEIGHT = 48;

export default function WorkOrderTooligs(props) {

    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, totalCount, pageSize, inProgress, data } = tableState;
    const message = useContext(ToastMessageContext);
    const [currentSelection, setCurrentSelection] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState('');
    const { workOrderId, mroOwned } = props;
    const [searchText, setSearchText] = useState("");
    const [status, setStatus] = useState("All")
    const debouncedVal = useDebounce(searchText)
    // const ref = useRef();

    const tableOptions = {
        ...options,
        page: page,
        search: false,
        total: totalCount,
        pageSize: pageSize,

    };

    useEffect(() => {
        if (workOrderId) {
            getList();
        }
    }, [pageSize, page, debouncedVal, totalCount, props?.createdId, status]);

    // useEffect(() => {
    //     if (props.hasOwnProperty('getList'))
    //         props.getList(getList)
    // }, [props.getList])

    function getList() {
        let params = {
            reportable_id: workOrderId,
            page: page + 1,
            count_per_page: pageSize,
            reportable_type: "WorkOrder",
            web_status: status,
            search_keyword: debouncedVal
        }
        TableAction.getList(dispatch, message, params, 'operation_workorder_tooling');
    }

    const handleClick = (event) => {
        setOpen(true)
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(false)
        setAction(null)
        setCurrentSelection({})
    };

    const handleOption = (option, data) => {
        if (option === 'Delete') {
            setAction('delete')
            setCurrentSelection(data)
        }
        else if (option === 'Show Certificate') {
            if (!currentSelection?.calibration_certificate) {
                return message.showToastMessage({ message: 'No certificate found!', variant: 'error' })
            }
            window.open(`${BASE_API_URL_BOXR}${currentSelection.calibration_certificate}`)
        }
    }

    useEffect(() => {
    }, [action])

    const handleDelete = (e) => {
        const formData = new FormData()
        formData.append("id", currentSelection?.id)
        // let data = {
        //     id: workOrderId,
        //     // reportable_type: "WorkOrder",
        //     // tool_primary_key: currentSelection.tool_primary_key
        // }
        // DataServiceAction.deleteData(dispatch, message, null, data, 'operation_workorder_tooling', handleCloseMenu, null, getList)
        OperationService.deleteTooling(formData).then(res => {
            if (res.success) {
                handleCloseMenu();
                message.showToastMessage({ message: "Tooling deleted successfully", variant: "success" });
                getList();
            }
            else {
                message.showToastMessage({ message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
            }
        }).catch(() => {
            handleCloseMenu();
            message.showToastMessage({ message: "Something went wrong. Try Again", variant: "error" });
        })

    }

    const columns = [
        {
            title: 'Task #',
            field: 'task',
            hidden: mroOwned !== "request_part" && "true",
            headerStyle: {
                textAlign: 'left',

            },
            cellStyle: {
                textAlign: 'left',
            }
        },
        {
            title: 'Tool #',
            field: 'tool_number',
            headerStyle: {
                textAlign: 'left',
                paddingLeft: mroOwned !== "request_part" ? 10 : 0,
            },
            cellStyle: {
                textAlign: 'left',
                paddingLeft: mroOwned !== "request_part" ? 10 : 0,
            }
        }, {
            title: 'Part #',
            field: 'part',
            headerStyle: {
                textAlign: 'left',
            },
            cellStyle: {
                textAlign: 'left',
                // width: '0.8%'
            }
        },
        {
            title: 'Description',
            field: 'description',
            width: "25%",
            headerStyle: {
                textAlign: 'left',
                width: "25%",
            },
            cellStyle: {
                textAlign: 'left',
                width: "25%",
            }
        },
        {
            title: 'Cal Due',
            field: 'calibration_due',
            render: rowData => format24HrDateOnly(rowData?.calibration_due),
            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
                // width: '0.8%'
            }
        },
        {
            title: 'User',
            field: 'user',
            headerStyle: {
                textAlign: 'left',
            },
            cellStyle: {
                textAlign: 'left',
                // width: '0.8%'
            }
        },
        {
            title: 'Status',
            field: 'status',

            render: (rowData) => {
                if (rowData?.new_status != null) {
                    return (
                        <MROButton
                            variant="outlined"
                            style={{
                                backgroundColor: rowData?.new_status === 0 ? "red" : "#4FC605",
                                color: "white",
                                borderRadius: "6px",
                                pointerEvent: "none",
                                textTransform: "uppercase",
                                // minWidth: "100px"
                                width: "101px",
                                height: "30px"
                            }}

                        >
                            <Typography style={{ fontSize: "12px" }}>
                                {rowData?.new_status === 0 ? "Booked Out" : "Returned"}
                            </Typography>
                        </MROButton>
                    );
                }
            }
        },
        {
            title: 'Action',
            field: '',
            render: rowData => <div id={`div-${rowData.id}`}>
                <IconButton
                    aria-label="more"
                    aria-controls={`long-menu-${rowData.id}`}
                    aria-haspopup="true"
                    onClick={(e) => { setCurrentSelection(rowData); handleClick(e) }}
                >
                    <MoreVertIcon />
                </IconButton>
            </div>,
            headerStyle: {
                textAlign: 'left',
                width: '7%'
            },
            cellStyle: {
                width: '7%'
            }
        }
    ]

    return (
        <>

            <SearchBarWithFilters
                handleSearch={(value) => {
                    TableAction.setPage(dispatch, 0);
                    setSearchText(value);
                }}
                searchText={searchText}
            >


                <FormControl size="medium" className={"filter"}>
                    <Select
                        fullWidth
                        displayEmpty
                        defaultValue={"All"}
                        onChange={(e) => setStatus(e.target.value)}
                        input={<InputBase />}
                    >
                        <MenuItem value={"All"}>Status (All)</MenuItem>
                        {["Booked Out", "Returned"].map((item, i) => (
                            <MenuItem key={i} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


            </SearchBarWithFilters>
            <Box mb={2.5}>
                <MaterialTable
                    columns={columns}
                    icons={tableIcons}
                    title=""
                    data={data.data}
                    isLoading={inProgress}
                    options={tableOptions}
                    onChangePage={(page) => {
                        TableAction.setPage(dispatch, page);
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                        TableAction.setPageSize(dispatch, pageSize);
                    }}
                    onSearchChange={(search) => {
                        TableAction.setSearchText(dispatch, search);
                    }}
                    totalCount={totalCount}
                    page={page}
                />
            </Box>
            {
                open && <Menu
                    id={`long-menu`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleCloseMenu}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    }}
                >{currentSelection?.new_status !== 0 && <MenuItem key={"Delete"} style={{ fontSize: '12px' }} onClick={() => { handleOption("Delete", currentSelection); }}>
                    {"Delete"}
                </MenuItem>}
                    {currentSelection?.calibration_certificate ? options1.map((option) => (
                        <MenuItem key={option} style={{ fontSize: '12px' }} onClick={() => { handleOption(option, currentSelection); }}>
                            {option}
                        </MenuItem>
                    )) : options2.map((option) => (
                        <MenuItem key={option} style={{ fontSize: '12px' }} onClick={() => { handleOption(option, currentSelection); }}>
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
            }
            {
                action === 'delete' && <ManageDataDialog type={'delete'} action={action} handleClose={handleCloseMenu} handleSubmit={handleDelete}>
                    Are you sure? Confirm Delete.
                </ManageDataDialog>
            }
        </>
    )
}
