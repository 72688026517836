import React, { useState } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import {
  AppBar,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  Box,
  ButtonGroup,
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import MROButton from "components/buttons";
import Search from "./Search";
import CreateNew from "./CreateNew";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  root: { "& > :first-child": { position: "unset" } },
  wrapper: {
    padding: "3%",
    background: "white",
  },
}));

function CreateAlternativePart(props) {
  const { onClose, baseStationId, lineCustomerId, acReg, workOrderId, sectionDetails, taskDetails } = props;
  const classes = useStyles();
  const [state, setState] = useState("search");





  // console.log("addparts=====", removedparts)

  return (
    <MROFullScreenRightDrawer open={true} className={classes.root}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            onClick={onClose}
            edge="start"
            className={classes.backButton}
            color="inherit"
            aria-label="back"
          >
            <BackIcon />
          </IconButton>
          <Typography variant="subtitle2" style={{ fontSize: "17px" }}>Add Part</Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.wrapper}>
        <Box mt="25px" mb="25px">
          <ButtonGroup color="primary">
            <MROButton
              variant={state === "search" ? "contained" : "outlined"}
              onClick={() => setState("search")}
              style={{
                width: "125px",
                color: state === "search" ? "white" : undefined,
              }}
            >
              Search
            </MROButton>
            <MROButton
              variant={state === "create" ? "contained" : "outlined"}
              onClick={() => setState("create")}
              style={{
                width: "125px",
                color: state === "create" ? "white" : undefined,
              }}
            >
              Create New
            </MROButton>
          </ButtonGroup>
        </Box>

        <Box width="100%">
          {state === "search" ? (
            <Search
              onClose={onClose}
              baseStationId={baseStationId}
              lineCustomerId={lineCustomerId}
              acReg={acReg}
              workOrderId={workOrderId}
              taskDetails={taskDetails}
            />
          ) : (
            <CreateNew
              onClose={onClose}
              baseStationId={baseStationId}
              lineCustomerId={lineCustomerId}
              acReg={acReg}
              workOrderId={workOrderId}
              taskDetails={taskDetails}
            />
          )}
        </Box>
      </div>
    </MROFullScreenRightDrawer>
  );
}

export default CreateAlternativePart;
