import { IconButton } from "@material-ui/core";
import { Box, Typography, Divider, Grid, Paper, useTheme, makeStyles, FormControl, alpha, Button, Menu, FormControlLabel } from '@material-ui/core'


import React, { useState, useEffect, useContext, useMemo } from 'react'
import ReactApexChart from "react-apexcharts";
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import DownloadFile from "./DownloadFile";
import { DatePicker } from 'antd';
import SearchBarWithFilters from "components/search_with_filters";
import { LineMaintainenceServices } from "lib/services/api/dashboard/line_maintenance";
import { getBaseStationID, getOrganisationId } from "lib/utils/common_utils";
import _ from "lodash";
import moment from "moment";
import ChartLoader from "views/Dashboard/Workshops/Storage/ChartLoader";
import { ToastMessageContext } from 'lib/contexts/message_context'
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import _without from "lodash/without";
import PositiveSwitch from 'components/form_components/switch'
import LineCustomerService from 'lib/services/api/admin/line/line_customer_client_new'
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation'
const { RangePicker } = DatePicker


function ManHours() {
    const [action, setAction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [customerDetails, setCustomerDetails] = useState([]);
    const message = useContext(ToastMessageContext)
    const [selectedFilters, setSelectedFilters] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorBase, setAnchorBase] = useState(null);

    const [custButton, setCustButton] = useState(false)
    const [bases, setBases] = useState([]);

    const [selectedBase, setSelectedBase] = useState([getBaseStationID()]);
    const [baseButton, setBaseButton] = useState(false)

    const [value, setValue] = useState([moment().subtract(1, 'month'), moment()])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [filterData, setFilterData] = useState({});

    const [labels, setLabels] = useState([])
    //customer filters
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setCustButton(!custButton)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setCustButton(!custButton)
    };

    const filterCustomerChangeHandler = (e) => {
        const { name, value, checked } = e.target
        // console.log("customer======", value, checked)
        let value1 = customerDetails.map((item) => {

            if (item.id === parseInt(value)) {
                item.status = !item.status
            }
            return item
        })

        setSelectedFilters((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedFilters((current) => _without(current, value));
        }

    }
    //basefilters
    const filterChangeHandler = (e) => {
        const { name, value, checked } = e.target
        let value1 = bases.map((item) => {

            if (item.id === parseInt(value)) {
                item.status = !item.status
            }
            return item
        })
        setSelectedBase((prevState) => [...prevState, value])
        if (!checked) {
            if (selectedBase.length > 1) {
                let item = selectedBase.filter((item) => item !== value)

                setSelectedBase(item);

            }
            else {
                setSelectedBase([])
            }
        }

    }
    const handleBaseClick = (event) => {
        setAnchorBase(event.currentTarget);
        setBaseButton(!baseButton)
    };

    //date filters

    const handleDateChange = (value) => {
        setStartDate(true)
        setEndDate(true)
        setValue(value)
        if (value) {
            const startdate = moment(value[0]).format("YYYY-MM-DD");
            const enddate = moment(value[1]).format("YYYY-MM-DD");
            let dayDiff = moment(value[0]).diff(value[1], 'days')

            if (Math.abs(dayDiff) > 365) {
                setValue(null)
                setFilterData({})
                message.showToastMessage({
                    message: "Date range exceeds 1-year maximum!",
                    variant: "error",
                });

            }
            else {
                setFilterData((prevState) => ({ ...prevState, startdate, enddate }));
            }

        } else {
            const fData = { ...filterData };
            filterData?.startdate && delete fData.startdate;
            filterData?.enddate && delete fData.enddate;
            setFilterData({ ...fData });
        }
    };

    //Api calls
    const getMainHoursData = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            start_date: startDate ? filterData.startdate : moment().subtract(1, 'month').format("YYYY-MM-DD"),
            end_date: endDate ? filterData.enddate : moment().format("YYYY-MM-DD"),

        }
        selectedBase.forEach((item, index) => params[`base_station_id[${[index]}]`] = item)
        selectedFilters.forEach((item, index) => params[`line_customer_id[${[index]}]`] = item)
        await LineMaintainenceServices.getMaintHoursDetails(params).then((response) => {

            if (response.success) {
                setLabels(response.data.labels)
                setState((prevState) => {

                    const updatedState = _.clone(prevState);
                    return {
                        ...updatedState,

                        series: response.data.dataset,
                        options: {
                            ...updatedState.options,
                            xaxis: {
                                categories: response.data.labels,
                                labels: {
                                    formatter: (value) => {
                                        return moment(value).format('DD/MMM')
                                    },
                                    rotateAlways: true,
                                }
                            },

                            legend: {
                                show: true,
                                position: "bottom",
                                horizontalAlign: "center",
                                // floating: true,
                                // offsetx: -5,
                                offsetY: 8,
                                markers: {
                                    width: [15, 15, 15, 15, 15, 15, 15, 15],
                                    height: [15, 15, 15, 15, 15, 15, 15, 15],
                                    strokeWidth: 1,
                                    strokeColor: '#fff',
                                    borderRadius: '5px',
                                    radius: [2, 2, 2, 2, 2, 2, 2, 2],


                                },

                            },

                        }
                    }
                })
            }
        }).catch((e) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {

            setLoading(false)
        })

    }

    useEffect(() => {

        getMainHoursData()
    }, [selectedBase, selectedFilters, filterData])

    //Line customer api
    const getCustomerList = async () => {
        await LineCustomerService.getLineCustomers()
            .then((res) => {


                let data = res.line_customers
                if (data.length > 0) {
                    let value = data.map((item) => ({
                        ...item,
                        status: false
                    }))
                    setCustomerDetails(value);
                }
                else {

                    setCustomerDetails(res.line_customers);

                }


            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    const getBaseStation = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            order_by: 'station'
        }
        MaintenanceServices.getBaseStation(params)
            .then((res) => {
                if (res) {
                    if (res) {

                        let data = res.data
                        if (data.length > 0) {
                            let value = data.map((item) => ({
                                ...item,
                                status: getBaseStationID() === item.id ? true : false
                            }))
                            setBases(value);
                        }
                        else {

                            setBases(res.line_customers);

                        }
                    }

                }

            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });

            });
    };
    useEffect(() => {
        getCustomerList()
        getBaseStation()
    }, [])

    const ITEM_HEIGHT = 78;


    const [state, setState] = useState({
        series: [],
        options: {
            chart: {
                height: 550,
                width: "100%",
                type: "line",
                stacked: false,

                dropShadow: {
                    enabled: false,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                },
            },

            colors: ["#FFB300", "#145999", "#26B4E8", "#4FC605", "#FF0202", "#000000", "#A4BFD8", "#707070"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: [2, 2, 2, 2, 2],
            },

            grid: {
                row: {
                    colors: ['transparent', 'transparent'],
                    opacity: 0.5
                },
                column: {
                    colors: ['transparent', 'transparent'],
                },
                xaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            markers: {
                size: 0,
            },
            xaxis: {
                categories: [],

            },



        }
    })
    return (
        <div>
            <SearchBarWithFilters noSearch padding={'4px 16px'}
            >
                <FormControl style={{ marginLeft: '-5px' }}>
                    <Button variant={"text"} size="small" aria-haspopup="true" onClick={handleClick} endIcon={custButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorEl ? '#efefef' : "" }} >
                        {selectedFilters.length > 0 ? `Customer(${selectedFilters.length})` : 'Customer(All)'}
                    </Button>
                    <Menu
                        id="Customer"
                        anchorEl={anchorEl}
                        keepMounted
                        open={anchorEl}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ marginTop: '41px' }}
                        TransitionProps={false}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.2,
                                width: '39ch',
                                position: 'fixed',
                                top: '260px',

                            },
                        }}
                    >

                        {customerDetails?.map((option, index) => {
                            return <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={9} style={{
                                        display: "flex", alignItems: "center", fontSize: "14px",

                                    }}>
                                        <label>{option.name}</label>
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                        <FormControlLabel

                                            control={<PositiveSwitch
                                                checked={option.status}
                                                value={option.id}
                                                onChange={(e) => { filterCustomerChangeHandler(e) }}
                                                name={option.name} />}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid><Divider variant="middle" /></Grid>
                            </Box>
                        })}
                    </Menu>
                </FormControl>
                <FormControl >
                    <Button variant={anchorBase ? "contained" : "text"} size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />} style={{ fontSize: "14px", backgroundColor: anchorBase ? '#efefef' : "" }}>
                        {selectedBase.length > 0 ? `Base(${selectedBase.length})` : 'Base(All)'}

                    </Button>
                    <Menu
                        id="Base"
                        anchorEl={anchorBase}
                        keepMounted
                        open={Boolean(anchorBase)}
                        onClose={() => {
                            setAnchorBase(null)
                            setBaseButton(!baseButton)
                        }}
                        // anchorOrigin={{
                        //     vertical: 'bottom',
                        //     horizontal: 'left',
                        // }}
                        style={{ marginTop: '40px' }}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '39ch',
                                position: 'absolute',
                                top: '260px',
                            },
                        }}
                    >
                        {
                            bases?.map((base, index) => {
                                return <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={8} style={{
                                            display: "flex", alignItems: "center", fontSize: "14px",

                                        }}>
                                            <label>{base.station}</label>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                style={{ alignSelf: "flex-end" }}
                                                control={<PositiveSwitch
                                                    checked={base.status}
                                                    value={base.id}
                                                    // onChange={(e) => handleChange(e, option.id)}
                                                    onChange={(e) => { filterChangeHandler(e, index) }}
                                                    name={base.station} />}
                                            // name="base"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid><Divider variant="middle" /></Grid>
                                </Box>
                            }

                            )}
                    </Menu>
                </FormControl>
                <FormControl style={{ float: "right" }}>
                    <RangePicker
                        format="DD/MM/YYYY"
                        onChange={handleDateChange}
                        allowClear={true}
                        // defaultValue={[moment().subtract(1, 'months'), moment()]}
                        value={value}
                    //   disabledDate={(current) => handleDisabledRange(current)}
                    />
                </FormControl>
            </SearchBarWithFilters>

            <Paper style={{ width: "100%", padding: "30px 35px", height: '560px' }}>
                <Box width="100%" display="flex" justifyContent="space-between">
                    <Typography color="primary" variant="h6">
                        Total Man-Hours
                    </Typography>

                    <IconButton style={{ marginTop: '-3px' }} onClick={() => setAction("download")} >
                        <CloudDownloadIcon />
                    </IconButton>

                </Box>
                {loading ? <ChartLoader /> :
                    <Box width="100%" display="flex" >
                        <Box width="98%">
                            <ReactApexChart
                                options={state.options}
                                series={state.series}
                                type="line"
                                height={450}
                            />
                        </Box>
                    </Box>}
                {action && action === 'download' && <DownloadFile handleClose={() => setAction(null)} selectedBase={selectedBase} selectedFilters={selectedFilters} labels={labels} />}
            </Paper>
        </div>
    )
}

export default ManHours