import React, { useState, useEffect, useReducer, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MROFullScreenRightDrawer from "../../../../components/fullwidth_dialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { Box, Dialog } from "@material-ui/core";
import { Visibility as ViewIcon } from "@material-ui/icons";
import { ICON_COLOUR } from "../../../../lib/constants/style_constants";
import Grid from "@material-ui/core/Grid";
import { ToastMessageContext } from "../../../../lib/contexts/message_context";
import tableIcons, {
  options,
} from "../../../../components/universal/table_attributes";
import tableReducer, {
  INITIAL_TABLE_STATE,
} from "../../../../reducers/table_reducer";
import MaterialTable from "material-table";
import TableAction from "../../../../actions/table_actions";
import Document from "../../../../components/form_components/fileUpload/pdf.png";
import { Edit as EditIcon } from "@material-ui/icons";
import RequestListClientServices from "lib/services/api/procurement/request_list";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  root: {
    backgroundColor: "#ffffff",
  },
  wrapper: {
    padding: "0 24px",
  },
  mainTitle: {
    fontSize: "20px",
    paddingTop: "25px",
  },
  title: {
    fontSize: 20,
    // fontWeight: "normal"
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
    marginTop: "20px",
    color: "#4D4F5C",
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
  },
  partKey: {
    fontSize: "14px",
    // padding: "5px",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "5px",
    display: "flex",
    alignItems: "center",
  },
  alternatesInfo: {
    fontSize: "16px",
    color: "#4D4F5C",
    marginTop: "15px",
    display: "block",
  },
  fileView: {
    backgroundColor: "#ffffff",
    padding: "15px",
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: " 18px",
    margin: "12px",
    "& ul": {
      margin: "0",
      display: "flex",
      padding: "0",
      listStyle: "none",
      flexWrap: "wrap",
      "& li": {
        margin: "0 10px",
        textAlign: "center",
        "& div": {
          width: "60px",
          height: "60px",
          position: "relative",
          marginBottom: "10px",
          "& img": {
            maxWidth: "100%",
            height: "-webkit-fill-available",
            objectFit: "cover",
          },
          "& div": {
            position: "absolute",
            top: "0",
            right: "0",
            width: "20px",
            backgroundColor: "#F44336",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            borderRadius: "50%",
          },
        },
        "& span": {
          width: "60px",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        },
      },
    },
  },
}));

export const GetInfoCard = (props) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Box flex={50} className={classes.partKey}>
        <Typography style={{ padding: "5px", fontSize: '14px' }} color={props.color ?? "secondary"}>
          {props?.keyName}
        </Typography>
      </Box>
      <Box flex={50} className={classes.partValue} style={{ fontWeight: props.biggerAndBoldValue && 'bold', fontSize: '14px', marginLeft: props.marginLeft && '124px' }}>
        {props?.value || "-"}
      </Box>
    </Box>
  );
};

function StockInfo(props) {
  const classes = useStyles();
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize } = tableState;
  const [loading, setLoading] = useState(false);
  const { currentSelection, handleClose, partInfo } = props;

  const [openMsds, setOpenMsds] = useState(false);
  const [msdsList, setMsdsList] = useState(false);

  const [stockList, setStockList] = useState([]);
  const [alternateList, setAlternateList] = useState([]);

  const [currentPartId, setCurrentPartId] = useState(
    currentSelection.primary_part_id
  );

  const [alternateTableState, setAlternateTableState] = useState({
    page: 0,
    totalCount: 5,
    pageSize: 10,
    loading: false,
  });

  const getPartList = (stockFetchType, setList) => {
    stockFetchType === "stock"
      ? setLoading(true)
      : setAlternateTableState((prevState) => {
        return { ...prevState, loading: true };
      });
    const params = {
      id: currentPartId,
      page:
        stockFetchType === "stock" ? page + 1 : alternateTableState.page + 1,
      count_per_page:
        stockFetchType === "stock" ? pageSize : alternateTableState.pageSize,
    };

    RequestListClientServices.getPartList(params, stockFetchType)
      .then((res) => {
        if (res?.data) {
          setList(res.data);
          if (stockFetchType === "stock") {
            TableAction.setTotalCount(dispatch, res?.total_count);
          } else {
            setAlternateTableState((prevState) => {
              return { ...prevState, totalCount: res?.total_count };
            });
          }
        } else setList([]);
        // setLoading(false);
      })
      .catch(() => {
        // setLoading(false);
      })
      .finally(() => {
        stockFetchType === "stock"
          ? setLoading(false)
          : setAlternateTableState((prevState) => {
            return { ...prevState, loading: false };
          });
      });
  };

  useEffect(() => {
    setLoading(true);
    getPartList("stock", setStockList);
    getPartList("alternateList", setAlternateList);
  }, [currentPartId]);

  useEffect(() => {
    getPartList("stock", setStockList);
  }, [page, pageSize]);

  useEffect(() => {
    getPartList("alternateList", setAlternateList);
  }, [alternateTableState.pageSize, alternateTableState.page]);

  const handleMsdsClose = () => {
    setOpenMsds(!openMsds);
  };

  // Ui-table column config
  const stockColumnConfig = [
    {
      title: "QTY",
      field: "quantity",
      headerStyle: {
        paddingLeft: "19px",
        textAlign: "left",
      },
      cellStyle: {
        paddingLeft: "19px",
        textAlign: "left",
      },
    },
    {
      title: "State",
      field: "state",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
    },
    {
      title: "Base",
      field: "base_station",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
      render: (rowData) => rowData?.base_station || "-",
    },
    {
      title: "Owner",
      field: "owner",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
      render: (rowData) => rowData?.owner || "-",
    },
    {
      title: "Batch #",
      field: "batch_number",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
      render: (rowData) => rowData?.batch_number || "-",
    },
    {
      title: "Serial #",
      field: "serial_number",
      width: "10%",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (rowData) => rowData?.serial_number || "-",
    },
    {
      title: "Location",
      field: "store_location",
      render: (rowData) => rowData?.store_location
        || "-",
    },
    {
      title: "Shelf-life",
      field: "shelf_life_expiry",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (rowData) => rowData?.shelf_life_expiry || "-",
    },
  ];

  // tableOptions
  const stockTableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false,
    headerStyle: {
      paddingTop: "22px",
      paddingBottom: "15px",
      textAlign: "center",
    },
  };

  // Ui-table column config
  const alternatesColumnConfig = [
    {
      title: "Part #",
      field: "part_number",
      width: "4%",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "19px",
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "19px",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
    },
    {
      title: "Stock",
      field: "stock",
    },
    {
      title: "Action",
      field: "id",
      render: (rowData) => (
        <IconButton disabled={!props?.permission?.write}>
          <ViewIcon fontSize="small" htmlColor={ICON_COLOUR} />
        </IconButton>
      ),
    },
  ];

  // tableOptions
  const alternatesTableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false,
    headerStyle: {
      paddingTop: "22px",
      paddingBottom: "15px",
      textAlign: "center",
    },
  };

  return (
    <MROFullScreenRightDrawer open={true}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            onClick={handleClose}
            edge="start"
            className={classes.backButton}
            color="inherit"
            aria-label="back"
          >
            <BackIcon />
          </IconButton>
          <Typography variant="subtitle2" className={classes.title}>
            Requests
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Typography
            variant={"subtitle1"}
            color={"primary"}
            className={classes.mainTitle}
          >
            Stock Information
          </Typography>
          <Typography variant="subtitle2" className={classes.subTitle}>
            Part Details
          </Typography>
          <div style={{ width: "100%", marginBottom: "20px" }}>
            <Box
              display="flex"
              alignItems="center"
              bgcolor="#F5F5F5"
              className={classes.partCard}
            >
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard
                    biggerAndBoldValue
                    keyName={"Part Number"}
                    value={partInfo?.part_number} color="primary"
                  />
                  <GetInfoCard

                    keyName={"Description"}
                    value={partInfo?.description} color="primary"
                  />
                  <GetInfoCard
                    biggerAndBoldValue
                    keyName={"Total Stock"}
                    value={partInfo?.total_stock} color="primary"
                  />
                </div>
              </Box>
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard
                    keyName={"Units of Measure"}
                    value={partInfo?.units_of_measure} color="primary"
                  />
                  <GetInfoCard
                    keyName={"Min QTY Issue"}
                    value={partInfo?.units_issued} color="primary"
                  />
                  <GetInfoCard
                    keyName={"Shelf-life control"}
                    value={partInfo?.shelf_life_control ? "Yes" : "No"} color="primary"
                  />
                </div>
              </Box>
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Unit Value"} value={partInfo?.cost} color="primary" />
                  <GetInfoCard
                    keyName={"Value Date"}
                    value={partInfo?.cost_date} color="primary"
                  />
                  <GetInfoCard
                    keyName={"Last Supplier"}
                    value={partInfo?.supplier} color="primary"
                  />
                </div>
              </Box>
              <Box flexGrow={1}>
                {
                  <div style={{ width: "100%" }}>
                    <Box display="flex">
                      <Box
                        flex={50}
                        textAlign="right"
                        className={classes.partKey}
                      >
                        <Typography color="primary">MSDS</Typography>
                      </Box>
                      <Box flex={50} className={classes.partValue}>
                        <ViewIcon
                          onClick={() => {
                            setOpenMsds(true);
                            setMsdsList(partInfo?.mds);
                          }}
                          fontSize="small"
                          htmlColor={ICON_COLOUR}
                        />
                      </Box>
                    </Box>
                  </div>
                }
              </Box>
            </Box>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="subtitle2" className={classes.subTitle}>
                Stock Details
              </Typography>
              <MaterialTable
                style={{ boxShadow: "0px 1px 3px #00000033" }}
                headerStyle={{ padding: "50px" }}
                icons={tableIcons}
                title={
                  <Typography variant="subtitle2">Min / Max Levels</Typography>
                }
                columns={stockColumnConfig}
                data={stockList || []}
                isLoading={loading}
                options={stockTableOptions}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Search anything",
                  },
                  body: {
                    emptyDataSourceMessage: "No Stock Found",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                  },
                }}
                onChangePage={(page) => {
                  TableAction.setPage(dispatch, page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                  TableAction.setPageSize(dispatch, pageSize);
                }}
                onSearchChange={(search) => {
                  TableAction.setSearchText(dispatch, search);
                }}
                totalCount={totalCount}
                page={page}
                actions={[
                  (rowData) => {
                    return {
                      icon: () => (
                        <MoreVertIcon
                          // fontSize="small"
                          htmlColor={ICON_COLOUR}
                          style={{ pointerEvents: "none" }}
                        />
                      ),
                      // tooltip: "Edit",
                      // disabled: props.permission.write,
                      onClick: (event, rowData) => { },
                    };
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" className={classes.subTitle}>
                Alternates *
              </Typography>
              <MaterialTable
                style={{ boxShadow: "0px 1px 3px #00000033" }}
                icons={tableIcons}
                title={
                  <Typography variant="subtitle2">Min / Max Levels</Typography>
                }
                columns={alternatesColumnConfig}
                data={alternateList || []}
                isLoading={loading}
                options={alternatesTableOptions}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Search anything",
                  },
                  body: {
                    emptyDataSourceMessage: "No Alternates Found",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                  },
                }}
                onChangePage={(page) => {
                  setAlternateTableState((prevState) => {
                    return {
                      ...prevState,
                      page: page,
                    };
                  });
                }}
                onChangeRowsPerPage={(pageSize) => {
                  setAlternateTableState((prevState) => {
                    return {
                      ...prevState,
                      pageSize: pageSize,
                    };
                  });
                }}
                onSearchChange={(search) => {
                  TableAction.setSearchText(dispatch, search);
                }}
                totalCount={totalCount}
                page={page}
              />
              <i className={classes.alternatesInfo}>
                * Alternates must be verified IAW approved maintenance data!
              </i>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        onClose={handleMsdsClose}
        aria-labelledby="simple-dialog-title"
        open={openMsds}
      >
        <div className={classes.fileView}>
          <ul>
            {msdsList?.length ? (
              msdsList.map((file, index) => {
                return (
                  <li key={file ? file[0]?.lastModified : ""}>
                    <div>
                      <img src={Document} />
                    </div>
                    <span>PDF Name.pdf</span>
                  </li>
                );
              })
            ) : (
              <span style={{ margin: "50px 100px" }}>No MSDS found</span>
            )}
          </ul>
        </div>
      </Dialog>
    </MROFullScreenRightDrawer>
  );
}

export default StockInfo;
