import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControl,
  Select,
  InputBase,
  MenuItem,
  FormControlLabel,
  Switch,
  InputAdornment,
} from "@material-ui/core";
import clxc from "clsx";
import SearchBarWithFilters from "components/search_with_filters";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MROButton from "components/buttons";
import useDebounce from "lib/utils/debounce";
import { ToastMessageContext } from "lib/contexts/message_context";
import AddPart from "./AddPartDetails/AddPart";
import PartInfo from "./PartInfo";
import AddIcon from "@material-ui/icons/Add";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import ToolingTable from "./ToolingTable";
import PositiveSwitch from "components/form_components/switch";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    "& .MuiOutlinedInput-multiline": {
      minHeight: "89px",
    },

    "& textarea::placeholder": {
      textTransform: "none !important",
    },

    "& form": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    bold: {
      fontWeight: 600,
    },
  },
  title: {
    fontSize: "17px",
    marginBottom: "10px",
    marginRight: "20px",
  },
  title2: {
    fontSize: "17px",
    margin: "20px 20px 10px 0px",
  },

  StockInfoDrawer: {
    "& > div[aria-hidden='true']": {
      visibility: "hidden !important",
    },
  },
  stockBtn: {
    borderColor: "#4D4F5C",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    width: "70px",
    backgroundColor: "#FFB300",
  },
}));

function PartsAndTools(props) {
  const classes = useStyles();
  const {
    bases,
    lineCustomers,
    currentSelectedOrder,
    orderState,
    partsAndToolsStatus
  } = props;

  const message = useContext(ToastMessageContext);

  // const user = JSON.parse(sessionStorage.getItem("user"));

  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, inProgress, data } = tableState;
  const [issued, setIssued] = useState(true);

  const [tableRefresh, setTableRefresh] = useState(false);

  const [searchText, setSearchText] = useState("");
  const debouncedVal = useDebounce(searchText);

  const [currentSelection, setCurrentSelection] = useState({});
  const [action, setAction] = useState(null);

  const [filters, setFilters] = useState({});

  const [actionDpdOptions, setActionDpdOptions] = useState([]);

  const filterChangeHandler = (key, value) => {
    setFilters(prevState => ({ ...prevState, [key]: value }));
  };

  const getActions = async () => {
    try {
      const { data } = await MaintenanceServices.getActionsDpdOptions({
        id: currentSelectedOrder.id,
      });
      setActionDpdOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActions();
  }, []);

  const closeHandler = () => {
    setTableRefresh(!tableRefresh);
    // setCreateSection(null);
    setAction(null);
  };
  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    pageSize: pageSize,
    searchText: searchText,
    toolbar: false,
  };

  const getList = () => {
    const params = {
      reportable_type: "WorkshopOrder",
      reportable_id: currentSelectedOrder.id,
      search_keyword: debouncedVal,
      page: 1 + page,
      count_per_page: pageSize,
      to_issued: issued,
      ...filters
    };

    TableAction.getList(dispatch, message, params, "workshopRequestPart");
  };

  useEffect(() => {
    getList();
  }, [page, debouncedVal, pageSize, filters, issued]);

  // const dataTable = [
  //   {
  //     tool_id: "1870",
  //     part_number: "ABC1234T-09123",
  //     description: "Cleaning Solution - 1L",
  //     call_due: "-",
  //     user: "7",
  //   },
  //   {
  //     tool_id: "098",
  //     part_number: "ABC1234T-09123",
  //     description: "Cleaning Solution - 1L",
  //     call_due: "23/Dec/2020",
  //     user: "7",
  //   },
  //   {
  //     tool_id: "0985",
  //     part_number: "ABC1234T-09123",
  //     description: "Cleaning Solution - 1L",
  //     call_due: "23/Dec/2020",
  //     user: "7",
  //   },
  //   {
  //     tool_id: "7658",
  //     part_number: "ABC1234T-09123",
  //     description: "Cleaning Solution - 1L",
  //     call_due: "23/Dec/2020",
  //     user: "7",
  //   },
  // ];

  const columns = [
    {
      title: "",
      field: "id",
      width: "4%",
      render: (rowData) => <span>&nbsp;</span>,
    },
    {
      title: "Action #",
      field: "action_number",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
    },
    {
      title: "Part Number",
      field: "part_number",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "20%",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "46%",
      },
    },
    {
      title: "Ordered",
      field: "required_quantity",
    },
    {
      title: "Allocated",
      field: "allocated_quantity",
    },
    {
      title: "Issued",
      field: "issued_quantity",
    },
    // {
    //   title: "Remaining",
    //   field: "pick_quantity",
    //   headerStyle: {
    //     textAlign: "center",
    //   },
    // },
    {
      title: "Status",
      render: (rowData) => {
        if (rowData?.approval_state === "un_approved" && rowData?.status != null) {
          return (
            <MROButton
              variant="outlined"
              style={{
                backgroundColor: "red",
                textDecoration: "underline",
                color: "white",
                borderRadius: "6px",
                PointerEvent: "none",
                textTransform: "uppercase",
                minWidth: "100px"
              }}
              onClick={() => {
                setAction("partDetails");
                setCurrentSelection(rowData);
              }}
            >
              <Typography style={{ wordWrap: "break-word" }}>
                <span style={{ marginRight: "3px" }}>
                  {/* {rowData?.status?.qty}{" "} */}
                </span>{" "}
                <span style={{ textTransform: "uppercase" }}>{"approval"}</span>
              </Typography>
            </MROButton>
          );
        } else if (
          rowData?.status?.status === "issued" ||
          (rowData?.approval_state === "approved" &&
            rowData?.issued_quantity === rowData?.required_quantity &&
            rowData?.status === null)
        ) {
          return (
            <MROButton
              variant="outlined"
              style={{
                borderColor: "darkgrey",
                textDecoration: "underline",
                color: "darkgrey",
                borderRadius: "6px",
                PointerEvent: "none",
                textTransform: "uppercase",
                minWidth: "100px"
              }}
              onClick={() => {
                setAction("partDetails");
                setCurrentSelection(rowData);
              }}
            >
              <Typography style={{ fontSize: "14px" }}>
                <span style={{ textTransform: "uppercase" }}>
                  {"all issued"}
                </span>
              </Typography>
            </MROButton>
          );
        } else if (
          rowData?.issued_quantity != rowData?.required_quantity &&
          rowData?.status === null
        ) {
          return (
            <MROButton
              variant="outlined"
              style={{
                borderColor: "darkgrey",
                // textDecoration: "underline",
                color: "darkgrey",
                borderRadius: "6px",
                PointerEvent: "none",
                textTransform: "uppercase",
                minWidth: "100px"
              }}
              onClick={() => {
                setAction("partDetails");
                setCurrentSelection(rowData);
              }}
            >
              <Typography style={{ fontSize: "14px" }}>
                <span style={{ textTransform: "uppercase" }}>{"--"}</span>
              </Typography>
            </MROButton>
          );
        } else {
          if (rowData?.status?.color != null) {
            var bgColor =
              rowData?.status?.color === "amber"
                ? "#FFB300"
                : rowData?.status?.color === "green"
                  ? "#4FC605"
                  : rowData?.status?.color === "red"
                    ? "red"
                    : "darkgrey";
          }
          return (
            rowData?.status != null && (
              <MROButton
                variant="outlined"
                style={{
                  backgroundColor: bgColor,
                  textDecoration: "underline",
                  border: "none",
                  color: "white",
                  borderRadius: "6px",
                  PointerEvent: "none",
                  textTransform: "uppercase",
                  minWidth: "100px"
                }}
                onClick={() => {
                  setAction("partDetails");
                  setCurrentSelection(rowData);
                }}
              >
                <Typography>
                  <span style={{ marginRight: "3px", fontSize: "14px" }}>
                    {rowData?.status?.qty}
                  </span>{" "}
                  <span
                    style={{ textTransform: "uppercase", fontSize: "14px" }}
                  >
                    {rowData?.status?.status || ""}
                  </span>
                </Typography>
              </MROButton>
            )
          );
        }
      },
    },
  ];

  return (
    <>
      <Typography color="primary" className={classes.title}>
        Parts
      </Typography>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            className={classes.bold}
          // style={{ marginLeft: 1000 }}
          >
            <MROButton
              disabled={!props?.permission.write}
              style={{ width: 140, height: 36, display: orderState === "Closed" && "none" }}
              variant={"contained"}
              color={"secondary"}
              onClick={() => setAction("view")}
              startIcon={<AddIcon size="small" />}
            >
              Add Part
            </MROButton>
          </Typography>
          <SearchBarWithFilters
            handleSearch={(value) => {
              TableAction.setPage(dispatch, 0);
              setSearchText(value);
            }}
            searchText={searchText}
          >
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl size="medium" className={"filter"}>
                <Select
                  name="workshop_action_id"
                  displayEmpty
                  defaultValue={""}
                  input={<InputBase />}
                  onChange={(e) => filterChangeHandler("workshop_action_id", e.target.value)}
                >
                  <MenuItem value={""}> Action (All)</MenuItem>
                  {actionDpdOptions.map((e) => (
                    <MenuItem
                      key={e.workshop_action_id}
                      value={e.workshop_action_id}
                    >
                      {e.action_number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ textAlign: "right" }}>
                &nbsp;
                <FormControlLabel
                  control={
                    <PositiveSwitch
                      defaultChecked={true}
                      checked={issued}
                      onChange={(e) => {
                        TableAction.setPage(dispatch, 0);
                        setIssued(e.target.checked);
                      }}
                    />
                  }
                  label=" Show ALL ISSUED items"
                  labelPlacement="start"
                // name={"show_all_issued_items"}
                />
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={issued}
                      onChange={(e) => {
                        TableAction.setPage(dispatch, 0);
                        setIssued(e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label="Show ALL ISSUED items"
                  labelPlacement="start"
                /> */}
              </div>
            </Grid>
          </SearchBarWithFilters>

          {/* Table */}
          <MaterialTable
            style={{ marginTop: 20 }}
            columns={columns}
            icons={tableIcons}
            title=""
            data={data.data || []}
            isLoading={inProgress}
            options={tableOptions}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            onSearchChange={(search) => {
              TableAction.setSearchText(dispatch, search);
            }}
            totalCount={totalCount}
            page={page}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography color="primary" className={classes.title2}>
            Tools
          </Typography>
          <ToolingTable
            classes={classes}
            currentSelectedOrder={currentSelectedOrder}
          />
          {/* Table */}
          {/* <MaterialTable
            style={{ marginTop: 20 }}
            columns={columnConfig}
            icons={tableIcons}
            title=""
            data={dummyData || []}
            isLoading={inProgress}
            options={tableOptions}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            onSearchChange={(search) => {
              TableAction.setSearchText(dispatch, search);
            }}
            totalCount={totalCount}
            page={page}
          /> */}
        </Grid>
      </Grid>
      {action === "view" && (
        <AddPart
          onClose={() => setAction(null)}
          bases={bases}
          lineCustomers={lineCustomers}
          currentSelectedOrder={currentSelectedOrder}
          actionDpdOptions={actionDpdOptions}
          getList={getList}
        />
      )}
      {action === "partDetails" && (
        <PartInfo
          permission={props?.permission}
          onClose={() => setAction(null)}
          currentSelectedRequest={currentSelection}
          actionDpdOptions={actionDpdOptions}
          getData={getList}
          closeHandler={closeHandler}
          partsAndToolsStatus={partsAndToolsStatus}
        />
      )}
    </>
  );
}

export default PartsAndTools;
