import { Box, IconButton, Tooltip, Typography } from '@material-ui/core'
import MaterialTable from 'material-table';
import React, { useCallback, useContext, useRef } from 'react'
import { useReducer } from 'react';
import { useEffect } from 'react';
import { useState } from 'react'
import { uniqBy } from 'lodash'
import TableAction from '../../../../actions/table_actions';
import MROButton from '../../../../components/buttons';
import ManageDialog from '../../../../components/form_components/manage_dialog';
import tableIcons, { options } from '../../../../components/universal/table_attributes';
import { ToastMessageContext } from '../../../../lib/contexts/message_context';
import tableReducer, { INITIAL_TABLE_STATE } from '../../../../reducers/table_reducer';
import SectionLabelAction from './FieldComponents/section_label_with_action'
import { Delete } from '@material-ui/icons';

const _ = require("lodash");

export default function AdditionalEngineers(props) {

    const { sectionDetails } = props;
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, totalCount, pageSize, searchText, inProgress, data } = tableState;
    const [showTableData, setShowTableData] = useState(false);
    const [action, setAction] = useState(null);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const message = useContext(ToastMessageContext)
    const tableRef = useRef()
    const tableOptions = {
        ...options,
        selection: true,
        total: totalCount,
        pageSize: pageSize,
        searchText: searchText
    }

    useEffect(() => {
        getUserList();
    }, [pageSize, page, searchText, totalCount])

    const handleTableData = data => () => {
        setShowTableData(true);
        // getOperationList(mapTasks[data.child_section_name])
        // setCurrentSelection(data);
    }

    const handleClose = () => {
        TableAction.setSearchText(dispatch, '');
        tableRef && tableRef.current && tableRef.current.onAllSelected(false);
        setShowTableData(false);
    }
    console.log("Additional", props.permission)

    const getUserList = () => {
        let params = {
            page: page + 1,
            count_per_page: pageSize,
            search_keyword: searchText
        }
        TableAction.getList(dispatch, message, params, 'fetchUserInfo')
    }

    const columns = [{
        title: 'Stamp #',
        field: 'stamp_number',
        headerStyle: {
            width: '30%',
            textAlign: 'center'
        },
        cellStyle: {
            width: '30%',
            textAlign: 'center'
        }
    },
    {
        title: 'Name',
        field: 'name' || 'username',
        headerStyle: {
            width: '75%',
            textAlign: 'left'
        },
        cellStyle: {
            width: '75%',
            textAlign: 'left'
        }
    }
    ]

    const _coumns = [{
        headerStyle: {
            width: '2%',
            textAlign: 'left'
        },
        cellStyle: {
            width: '3%',
            textAlign: 'left'
        }
    }, {
        title: 'Stamp #',
        field: 'stamp_number',
        headerStyle: {
            width: '30%',
            textAlign: 'left'
        },
        cellStyle: {
            width: '30%',
            textAlign: 'left'
        }
    },
    {
        title: 'Name',
        field: 'username',
        headerStyle: {
            width: '75%',
            textAlign: 'left'
        },
        cellStyle: {
            width: '75%',
            textAlign: 'left'
        }
    },
    {
        title: 'Action',
        render: rowData => <Tooltip title="Delete" arrow>
            <IconButton
                onClick={() => handleDeleteAddEngg(rowData)}
            >
                <Delete fontSize="small" />
            </IconButton>
        </Tooltip>
    }
    ]

    const handleDeleteAddEngg = (rowData) => {
        setTempEngg(tempEngg.filter(engg => engg.user_id !== rowData.user_id))
    }

    const section = sectionDetails?.sub_section_fields?.find(field => field.field_name === 'Additional Engineers');
    const _data = section?.field_value;
    console.log("Additional Engineer", section)
    const handleAddEngg = useCallback(
        () => {
            {
                // tableRef && tableRef.current && tableRef.current.onAllSelected(true);
                let arr = []
                if (!!selectedKeys.length && _data) {
                    arr = [...selectedKeys]
                } else if (!!selectedKeys.length) {
                    arr = [...selectedKeys];
                } else {
                    arr = [..._data]
                }
                setTempEngg([...tempEngg, ...arr])

                handleClose();

            }
        },
        [selectedKeys, _data],
    )


    const [tempEngg, setTempEngg] = useState([]);
    useEffect(() => {
        if (_data)
            setTempEngg(_.uniqBy([..._data], 'user_id'))
    }, [])

    console.log("tempEngg is==================", { tempEngg, selectedKeys })
    return (
        <>
            <Box width="100%" mt={3} ml={"-2px"}>
                <SectionLabelAction onClick={handleTableData()} name={'Additional engineers'} permission={props.permission} />
                <input form="mainform" style={{ display: 'none' }} name={`${section?.id}`} value={`[${tempEngg.map(_d => _d.user_id)}]`} />
                <Box width="50%" ml={"12px"}>
                    <MaterialTable
                        title=""
                        options={{ ...tableOptions, selection: false, search: false }}
                        isLoading={inProgress}
                        icons={tableIcons}
                        columns={_coumns}
                        data={tempEngg}
                    />
                </Box>

                {
                    showTableData && <ManageDialog handleClose={handleClose}>
                        <MaterialTable
                            tableRef={tableRef}
                            title={<Typography variant="subtitle2" color="primary">
                                Additional Engineers
                            </Typography>}
                            options={tableOptions}
                            isLoading={inProgress}
                            icons={tableIcons}
                            columns={columns}
                            data={data.users}
                            onChangePage={(page) => {
                                TableAction.setPage(dispatch, page);
                            }}
                            onChangeRowsPerPage={(pageSize) => {
                                TableAction.setPageSize(dispatch, pageSize);
                            }}
                            onSearchChange={(search) => {
                                TableAction.setSearchText(dispatch, search);
                            }}
                            totalCount={totalCount}
                            page={page}
                            onSelectionChange={(rows) => {
                                setSelectedKeys(rows.map(row => ({
                                    user_id: row.id,
                                    username: row.name,
                                    stamp_number: row.stamp_number
                                })))

                            }}
                        />
                        <Box mt={3} display="flex" justifyContent="flex-end">
                            <MROButton onClick={handleClose} variant="contained">
                                Cancel
                            </MROButton>
                            &nbsp;&nbsp;
                            <MROButton type="button" variant={"contained"} color="primary"
                                onClick={() => {
                                    handleAddEngg()
                                }}
                            >Add Additional Engineer(s)</MROButton>
                        </Box>
                    </ManageDialog>
                }
            </Box>
        </>
    )
}
