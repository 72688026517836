import React, { useContext, useEffect, useMemo, useState } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { ToastMessageContext } from "lib/contexts/message_context";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, MenuItem } from "@material-ui/core";
import { ICON_COLOUR } from "lib/constants/style_constants";
import DeleteCusApproval from "./DeleteCusApproval";
import MROButton from "components/buttons";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import { Visibility as ViewIcon } from "@material-ui/icons";
import YesNoField from "components/form_components/YesNoField";
import MROTextField from "components/form_components/TextField";
import WarningIcon from "@material-ui/icons/Warning";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import UploadButton from "components/form_components/upload_multiple";
import { validateFormData } from "lib/utils/helperFunctions";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import { _formatDate, formatToDateOnly, getOrganisationId, formatDateFunction } from "lib/utils/common_utils";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { downloadPdfFileFromUrl } from "lib/utils/helperFunctions";
import DownloadIcon from 'assets/DownloadIcon.svg'
import ApprovalCaution from "./ApprovalCaution";
import { GetInfoCard } from "views/Procurement/Parts/requestList/stockInfo";
import AwsDateTimeFieldFor from "components/form_components/AwsDateTimeFieldFor";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: "3%",
    },
    appBar: {
        backgroundColor: "#fff",
        color: "#000000CC",
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    wrap: {
        borderRadius: "5px",
        backgroundColor: "#fff",
        padding: theme.spacing(3),
        boxShadow: "0px 1px 4px #0000001A",
    },
    itemContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    actionButtons: {
        display: "flex",
        justifyContent: "flex-end",
        textAlign: "right",
        marginRight: "33px",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    partCard: {
        borderRadius: "8px",
        padding: "7px 24px",
    },
}));


export default function CustomerApprovalNew(props) {
    const classes = useStyles();
    const {
        handleClose,
        approvedPositionData,
        getApprovedPosition,
        currentSelectedId,
        approveData,
        actionMode,
        currentSelectedOrder
    } = props;
    const message = useContext(ToastMessageContext);
    const { setApprovalStateCommercial } = useContext(WorkshopContext);
    const [busy, setBusy] = useState(null);
    const [action, setAction] = useState(null);
    const [releaseType, setReleaseType] = useState(null);
    const [approvedToggle, setApprovedToggle] = useState("yes");
    const [errors, setErrors] = useState({});
    const [sum, setSum] = useState(null);
    const [proofAcceptnace, setProofAcceptance] = useState([]);
    const [proofRejection, setProofRejection] = useState([]);
    const [togglevalue, setToggleValue] = useState("yes")
    const [documents, setDocuments] = useState([])
    const [submissionData, setSubmissionData] = useState([])
    const [subLoading, setSubLoading] = useState(false)
    const [docLoading, setDocLoading] = useState(false)
    const user = JSON.parse(sessionStorage.getItem("user"));

    //Submission Table

    const [confirmationPopup, setConfirmationPopup] = useState(false)
    const [acceptAll, setAcceptAll] = useState(false)
    const [popupValues, setPopUpValues] = useState({})
    const [formvalues, setFormvalues] = useState({})
    const [tatRemaining, setTatRemaining] = useState()
    const [tatDays, setTatDays] = useState()
    const [date, setDate] = useState(null)
    const [etd, setETD] = useState(null)
    useEffect(() => {

        if (approveData?.quote_accepted || approveData?.quote_accepted === null) {
            setApprovedToggle("yes")
        }
        else if (!approveData?.quote_accepted) {
            setApprovedToggle("no")
        }
        if (approveData !== undefined) {
            setTatRemaining(approveData?.tat_remaining)
            setTatDays(approveData?.tat_days_count)
            setDate(moment(approveData?.approved_datetime, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss a"))
        }

    }, [approveData])




    const getSubmissionDetails = async () => {
        setSubLoading(true)
        const param = {
            id: currentSelectedId
        }
        await CommercialServices.getApprovalSubmissionTable(param).then((res) => {

            setSubmissionData(res.data)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setSubLoading(false)
        })



    }
    const getSubmissionDocumentDetails = async () => {
        setDocLoading(true)
        const param = {
            id: currentSelectedId
        }
        await CommercialServices.getApprovalSubmissionDocument(param).then((res) => {
            setDocuments(res.data)
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setDocLoading(false)
        })



    }


    useEffect(() => {
        getSubmissionDetails()
        getSubmissionDocumentDetails()
    }, [])



    //calculate etd and tat 
    useEffect(() => {
        if (date !== null && date !== undefined) {

            let tat = tatRemaining ? tatRemaining : 0
            let f_date = date ? moment(date, "YYYY-MM-DD HH:mm:ss").add(tat, "days").format("DD/MM/YYYY") : null
            setETD(f_date)
            if (f_date) {
                let date_format = "DD/MM/YYYY"
                let c_etd = moment(f_date, date_format)
                let inducted = currentSelectedOrder?.inducted_start ? moment(currentSelectedOrder?.inducted_start, "DD/MM/YYYY HH:mm:ss").format(date_format) : null

                if (inducted !== null) {
                    let i_date = moment(inducted, date_format)
                    let final_date = c_etd.diff(i_date, "days")

                    setTatDays(final_date)
                }
            }
        }

    }, [date, tatRemaining])

    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };

    const handleDownload = async (reportName) => {

        const params = {
            id: currentSelectedId,
            report: reportName

        }
        setDocLoading(true)
        await CommercialServices.downloadSubmissionDocument(params).then((res) => {
            if (res?.file) {
                downloadPdfFileFromUrl(res.file)
                message.showToastMessage({
                    message: "Report Downloaded Succesfully",
                    variant: "success",
                });
            }
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setDocLoading(false)
        })

    }



    const column = [
        {
            title: "",
            field: "title",
            width: "20%",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                width: "20%",
                textAlign: "left",
                paddingLeft: '12px'
            },
        },
        {
            title: "Position",
            field: "position",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {

                textAlign: "center",
            },
        },
        {
            title: "TAT ",
            field: "tat",
            render: (rowData) => !rowData.tat ? "" : rowData.tat
        },
        {
            title: "ETD",
            field: "etd",
            render: (rowData) => rowData?.etd ? formatToDateOnly(rowData?.etd) : ""
        },
        {
            title: "Labour",
            field: "labour",
            // render: (rowData) => !rowData.labour ? "0.00" : rowData.labour
        },
        {
            title: "Parts",
            field: "parts",
            // render: (rowData) => !rowData.parts ? "0.00" : rowData.parts
        },
        {
            title: "Services",
            field: "service",
            // render: (rowData) => !rowData.service ? "0.00" : rowData.service
        },
        {
            title: "Equipment",
            field: "equipment",
            // render: (rowData) => !rowData.equipment ? "0.00" : rowData.equipment
        },
        {
            title: "Other",
            field: "aog_fee",
            // render: (rowData) => !rowData.aog_fee ? "0.00" : rowData.aog_fee
        },
        {
            title: "Total",
            field: "total",
        },
        {
            title: "Currency",
            field: "currency",
        },
    ];

    const tableOptions = {
        ...options,
        search: false,
        paging: false,
        rowStyle: { height: 42 },
    };


    const tableOptions2 = {
        ...options,
        search: false,
        paging: false,
        rowStyle: { height: 42 },
    };



    const column2 = [
        {
            title: "Item",
            field: "",
            width: '80%',
            headerStyle: {
                textAlign: "left",
                paddingLeft: '12px'
            }, cellStyle: {
                width: '80%',
                textAlign: "left",
                paddingLeft: '12px'
            },
            render: (rowData) => {
                if (rowData?.collection_name === "commercial_report") {
                    return "Commercial Report"

                } if (rowData?.collection_name === "workshop_report") {
                    return "Workshop Report "

                } if (rowData?.collection_name === "cost_breakdown_report") {
                    return "Detailed Breakdown CSV"
                }

            }

        },

        {
            title: "View",
            field: "view",
            render: (rowData) => {
                if (rowData?.collection_name === "commercial_report") {
                    return (
                        <IconButton onClick={() => {
                            if (rowData?.file_path !== undefined) {
                                window.open(rowData?.file_path)
                            } else {
                                message.showToastMessage({
                                    message: "No Document Found.",
                                    variant: "error",
                                });
                            }
                        }}
                            size="small">
                            <VisibilityIcon fontSize="small" color={ICON_COLOUR} />
                        </IconButton>

                    )

                } if (rowData?.collection_name === "workshop_report") {
                    return (
                        <IconButton onClick={() => {
                            if (rowData?.file_path !== undefined) {
                                window.open(rowData?.file_path)
                            } else {
                                message.showToastMessage({
                                    message: "No Document Found.",
                                    variant: "error",
                                });
                            }
                        }}
                            size="small">
                            <VisibilityIcon fontSize="small" color={ICON_COLOUR} />
                        </IconButton>

                    )

                } if (rowData?.collection_name === "cost_breakdown_report") {
                    return ''
                }

            }
        },
        {
            title: "Download",
            field: "",
            render: (rowData) =>

                <IconButton onClick={() => {
                    handleDownload(rowData?.collection_name === "commercial_report" ? "commercial_report" : rowData?.collection_name === "workshop_report" ? "workshop_report" : rowData?.collection_name === "cost_breakdown_report" ? "cost_breakdown_report" : "")
                }}
                    size="small">
                    <img src={DownloadIcon} height={'16px'} />

                </IconButton>



        },
    ];





    const handleApprovedToggle = (value) => {
        if (value !== null) {
            setToggleValue(value)
            setApprovedToggle(value)
        }
        else {
            setApprovedToggle(togglevalue)
        }
    };

    useEffect(() => {
        getReleaseType();
    }, []);

    const getReleaseType = async () => {
        const params = {
            organisation_id: getOrganisationId()
        }
        await MaintenanceServices.getReleaseType(params)
            .then((res) => {
                if (res) {
                    setReleaseType(res.data);
                }
            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
            });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const { error, valid } = validateFormData(formData, [
            approvedToggle === "no"
                ? ("proof_of_acceptance", "approved_datetime")
                : "",
            approvedToggle === "yes"
                ? ("release_type", "reject_comments", "proof_of_rejection", "tat_remaining", "approved_datetime")
                : "",
        ]);

        if (approvedToggle === null) {
            setBusy(null);
            setErrors((er) => ({ ...er, quote_accepted: true }));
        }

        if (proofAcceptnace?.length === 0 && approvedToggle === "yes") {
            setBusy(null);
            setErrors((er) => ({ ...er, proof_of_acceptance: true }));
        }
        if (proofRejection?.length === 0 && approvedToggle === "no") {
            setBusy(null);
            setErrors((er) => ({ ...er, proof_of_rejection: true }));
        }

        if (
            (proofAcceptnace?.length === 0 && approvedToggle === "yes") ||
            (proofRejection?.length === 0 && approvedToggle === "no") || approvedToggle === null
        ) {
            setErrors((err) => ({ ...err, ...error }));
            setBusy(null);
            return;
        }

        if (!valid) {
            setBusy(null);
            setErrors(error);
            return;
        }
        const data = formData.get("approved_datetime")

        if (approvedToggle === "yes") {
            const etd = formData.get("etd")
            const f_etd = moment(etd, "DD/MM/YYYY").format("YYYY-MM-DD")
            formData.delete("etd")
            formData.append("etd", f_etd)
        }
        formData.delete("approved_datetime")
        formData.append("user_id", user?.id);
        formData.append("approved_datetime", moment(date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm"));
        formData.append("id", currentSelectedId);
        if (valid) {

            setConfirmationPopup(true)
            setFormvalues(formData)

        }
    };

    const handlePopUp = async (e) => {
        setBusy("loading");
        await CommercialServices.customerApproval(formvalues)
            .then((res) => {
                if (res) {
                    handleClose(res.success);
                    setApprovalStateCommercial(false);
                    getApprovedPosition();
                    message.showToastMessage({
                        message: "Approved successfully!!",
                        variant: "success",
                    });
                }
            })
            .catch(() => {
                message.showToastMessage({
                    message: "Something wnt wrong. Try again!!",
                    variant: "error",
                });
            })
            .finally(() => {
                setBusy(null);
            });



    }


    return (
        <div>
            <MROFullScreenRightDrawer open={true}>
                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box display="flex" alignItems="center">
                            <IconButton
                                onClick={handleClose}
                                edge="start"
                                className={classes.backButton}
                                color="inherit"
                                aria-label="back"
                            >
                                <BackIcon />
                            </IconButton>
                            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>
                                Approved Position
                            </Typography>
                        </Box>
                        {approveData?.highest_position ? <IconButton onClick={() => setAction("delete")}>
                            <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
                        </IconButton> : ""}
                    </Toolbar>
                </AppBar>

                <form noValidate onSubmit={handleSubmit}>

                    <div className={classes.wrapper}>
                        <Grid>
                            <Typography color="primary" style={{ marginBottom: "10px" }}>
                                Submission
                            </Typography>
                        </Grid>

                        <div style={{ width: "100%", marginBottom: "20px" }}>
                            <Box
                                display="flex"
                                alignItems="center"
                                bgcolor="#F5F5F5"
                                className={classes.partCard}
                            >
                                <Box flexGrow={1}>
                                    <div style={{ width: "100%" }}>
                                        <GetInfoCard
                                            keyName={"Approval #"}
                                            value={approveData?.approval_number === 0 ? "Quote" : approveData?.approval_number} color="primary"
                                        />
                                        <GetInfoCard

                                            keyName={"Submitted"}
                                            value={approveData?.approved_datetime ? formatDateFunction(approveData?.approved_datetime) : ""} color="primary"
                                        />
                                        <GetInfoCard

                                            keyName={"User"}
                                            value={approveData?.user_stamp} color="primary"
                                        />
                                    </div>
                                </Box>
                                <Box flexGrow={1}>
                                    <div style={{ width: "100%" }}>
                                        <GetInfoCard
                                            keyName={"Receipt Charge"}
                                            value={approveData?.receipt_value} color="primary"
                                        />
                                        <GetInfoCard
                                            keyName={"Return Charge"}
                                            value={approveData?.return_value
                                            } color="primary"
                                        />
                                        <GetInfoCard
                                            keyName={"Return Method"}
                                            value={
                                                approveData?.return_type
                                            } color="primary"
                                        />
                                    </div>
                                </Box>
                                <Box flexGrow={1}>
                                    <div style={{ width: "100%" }}>
                                        <GetInfoCard
                                            keyName={"Standard Price ID"}
                                            value={approveData?.workshop_standard_price_id
                                            } color="primary"
                                        />
                                        <GetInfoCard
                                            keyName={"Std Price Part #"}
                                            value={approveData?.std_price_part
                                            } color="primary"
                                        />
                                        <GetInfoCard
                                            keyName={"Std Price Description"}
                                            value={approveData?.std_price_description
                                            } color="primary"
                                        />
                                    </div>
                                </Box>
                            </Box>
                        </div>
                        <Grid item xs={12} >
                            <MROTextField
                                style={{ whiteSpace: 'break-spaces' }}
                                disabled={actionMode === "view_customer_Approval"}
                                label={"Comments"}
                                multiline
                                defaultValue={approveData?.comment}
                                inputProps={{ maxLength: 500 }}
                                InputProps={{
                                    className: classes.disableField,
                                    style: { height: 'max-content', width: "100%" }
                                }}
                                placeholder={
                                    "Applicable comments or notes - Max 500 characters"
                                }
                                InputLabelProps={{ shrink: true }}
                            ></MROTextField>
                        </Grid>


                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 4px #00000033",
                            }}
                            icons={tableIcons}
                            title={""}
                            columns={column}
                            data={submissionData || []}
                            isLoading={subLoading}
                            options={tableOptions}
                            components={{
                                Toolbar: () => null,
                            }}
                            localization={{
                                toolbar: {},

                            }}
                        />

                        <Grid>
                            <Typography color="primary" style={{ marginBottom: "20px", marginTop: '20px' }}>
                                Approval Documents
                            </Typography>
                        </Grid>

                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 4px #00000033",
                            }}
                            icons={tableIcons}
                            title={""}
                            columns={column2}
                            data={documents || []}
                            isLoading={docLoading}
                            options={tableOptions2}
                            components={{
                                Toolbar: () => null,
                            }}
                            localization={{
                                toolbar: {},
                                body: {
                                    emptyDataSourceMessage: "No data found",
                                    filterRow: {
                                        filterTooltip: "Filter",
                                    },
                                },
                            }}
                        />
                        <Grid>
                            <Typography color="primary" style={{ marginBottom: "10px", marginTop: '20px' }}>
                                Approval
                            </Typography>
                        </Grid>
                        <YesNoField
                            label="Quotation Approved? *"
                            name="quote_accepted"
                            handleToggle={handleApprovedToggle}
                            value={approvedToggle}
                            error={errors.quote_accepted}
                            setError={() => {
                                resetErrorsHandler("quote_accepted");
                            }}
                            disabled={actionMode === "view_customer_Approval"}
                        />
                        {approvedToggle === "no" && (
                            <Grid container spacing={1} className={classes.itemContainer}>
                                <Grid item>
                                    <Typography style={{ fontSize: "13px" }}>
                                        Is the item being Scrapped, or returned U/S? Update the
                                        order’s Release Type below to match the return state
                                    </Typography>
                                </Grid>
                                <Grid container xs={12} spacing={2}>
                                    <Grid item xs={12} md={3} style={{ marginTop: "8px" }}>
                                        <AwsDateTimeFieldFor
                                            required
                                            fullWidth
                                            pointerEvent={"none"}
                                            disabled={actionMode === "view_customer_Approval"}
                                            background={"#F5F5F5"}
                                            // format="ll - HH:mm"
                                            label={"Rejected Date / Time *"}
                                            format={"YYYY-MM-DD HH:mm:ss"}
                                            renderDate={"DD/MM/YYYY HH:mm"}
                                            placeholder="DD/MM/YYYY hh:mm"
                                            name="approved_datetime"
                                            errors={errors?.approved_datetime}
                                            onChange={(e) => {
                                                resetErrorsHandler("approved_datetime");

                                            }}
                                            // defaultValue={approveData?.rejected_datetime}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <MROTextField
                                            name={"release_type"}
                                            label={"Release Type 1*"}
                                            placeholder={"Select"}
                                            disabled={actionMode === "view_customer_Approval"}
                                            select
                                            defaultValue={approvedPositionData?.release_type_id}
                                            InputLabelProps={{ shrink: true }}
                                            error={errors.release_type}
                                            onChange={(e) => {
                                                resetErrorsHandler(e.target.name);
                                            }}
                                        >
                                            <MenuItem value="select">
                                                <span style={{ opacity: "40%" }}>Select</span>
                                            </MenuItem>
                                            {releaseType?.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.type_name}
                                                </MenuItem>
                                            ))}
                                        </MROTextField>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <MROTextField
                                            name={"reject_comments"}
                                            disabled={actionMode === "view_customer_Approval"}
                                            label={"Comments"}
                                            required
                                            multiline
                                            defaultValue={approveData?.reject_comment}
                                            InputProps={{ style: { height: 'max-content' } }}
                                            inputProps={{ maxLength: 250 }}
                                            error={errors.reject_comments}
                                            onChange={(e) => {
                                                resetErrorsHandler(e.target.name);
                                            }}
                                            placeholder={
                                                "Applicable comments or notes - Max 250 characters"
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        ></MROTextField>
                                    </Grid>

                                </Grid>
                                {actionMode === "customer_Approval" && <>
                                    <Grid item>
                                        <Typography>
                                            Upload proof of rejection (customer approval email etc) *
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <UploadButton
                                            multiple={false}
                                            accept={"application/pdf"}
                                            action={"Upload Approval"}
                                            noFileExist={"Attached Files:"}
                                            error={errors.proof_of_rejection}
                                            setFiles={(files) => {
                                                setProofRejection(files);
                                                resetErrorsHandler("proof_of_rejection");
                                            }}
                                            name={"proof_of_rejection"}
                                            label=""
                                            labelName="Proof of rejection (customer approval email etc) *"
                                        />
                                    </Grid>
                                </>}
                            </Grid>
                        )}

                        {approvedToggle === "yes" && (<>

                            <Grid container spacing={1} className={classes.itemContainer}>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid className={classes.purchaseGrid} item xs={3} style={{ marginTop: "8px" }}>
                                        <AwsDateTimeFieldFor
                                            required
                                            fullWidth
                                            pointerEvent={"none"}
                                            disabled={actionMode === "view_customer_Approval"}
                                            background={"#F5F5F5"}
                                            // format="ll - HH:mm"
                                            value={date}
                                            label={"Accepted Date / Time *"}
                                            format={"YYYY-MM-DD HH:mm:ss"}
                                            renderDate={"DD/MM/YYYY HH:mm"}
                                            placeholder="DD/MM/YYYY hh:mm"
                                            name="approved_datetime"
                                            errors={errors.approved_datetime}
                                            onChange={(e) => {
                                                resetErrorsHandler("approved_datetime");
                                                setDate(e)
                                            }}
                                            defaultValue={approveData?.approved_datetime}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid className={classes.purchaseGrid} item xs={3}>
                                        <MROTextField
                                            name={"tat_remaining"}
                                            label={"TAT Remaining (Days) *"}
                                            defaultValue={approveData?.tat_remaining}
                                            type="text"
                                            value={tatRemaining}
                                            placeholder={""}
                                            InputLabelProps={{ shrink: true }}
                                            disabled={actionMode === "view_customer_Approval"}
                                            error={errors.tat_remaining}
                                            onChange={(e) => {
                                                resetErrorsHandler("tat_remaining");
                                                setTatRemaining(e.target.value)
                                            }}
                                        ></MROTextField>
                                    </Grid>
                                    <Grid className={classes.purchaseGrid} item xs={3}>

                                        <MROTextField
                                            name={"tat_days"}
                                            label={"TAT (Days)*"}
                                            defaultValue={approveData?.tat}
                                            value={tatDays}
                                            type="text"
                                            placeholder={"0.00"}
                                            error={errors.tat_days}
                                            onChange={(e) => {
                                                resetErrorsHandler(e.target.name);
                                                setTatDays(e.target.value)
                                            }}
                                            InputProps={{
                                                className: classes.disableField,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                        ></MROTextField>


                                    </Grid>

                                    <Grid className={classes.purchaseGrid} item xs={3}>
                                        <MROTextField
                                            name={"etd"}
                                            label={"Calculated ETD"}
                                            defaultValue={approveData?.etd}
                                            type="text"
                                            value={etd}
                                            placeholder={""}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                className: classes.disableField,
                                            }}
                                        ></MROTextField>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <MROTextField
                                        name={"reject_comments"}
                                        label={"Comments"}
                                        required
                                        multiline
                                        disabled={actionMode === "view_customer_Approval"}
                                        defaultValue={approveData?.reject_comment}
                                        InputProps={{ style: { height: 'max-content', width: "100%" } }}
                                        inputProps={{ maxLength: 250 }}
                                        error={errors.reject_comments}
                                        onChange={(e) => {
                                            resetErrorsHandler(e.target.name);
                                        }}
                                        placeholder={
                                            "Applicable comments or notes - Max 250 characters"
                                        }
                                        InputLabelProps={{ shrink: true }}
                                    ></MROTextField>
                                </Grid>
                                {actionMode === "customer_Approval" && <>
                                    <Grid item xs={4} style={{ marginTop: "10px" }}>
                                        <Typography>
                                            Proof of acceptance (customer approval email etc) *
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <UploadButton
                                            multiple={false}
                                            accept={"application/pdf"}
                                            action={"Upload Approval"}
                                            noFileExist={"Attached Files:"}
                                            error={errors.proof_of_acceptance}
                                            setFiles={(files) => {
                                                setProofAcceptance(files);
                                                resetErrorsHandler("proof_of_acceptance");
                                            }}
                                            name={"proof_of_acceptance"}
                                            label=""
                                            labelName="Proof of acceptance (customer approval email etc) *"
                                        />
                                    </Grid></>}








                            </Grid>



                        </>)}

                    </div>
                    {actionMode === "customer_Approval" &&
                        <div className={classes.actionButtons}>
                            <MROButton variant={"contained"} onClick={handleClose}>
                                Cancel
                            </MROButton>
                            <MROButton
                                loading={busy === "loading"}
                                variant={"contained"}
                                color={"primary"}
                                type={"submit"}
                            >
                                Complete
                            </MROButton>
                        </div>}



                </form>
                {action === "delete" && (
                    <DeleteCusApproval
                        handleClose={() => setAction(null)}
                        open={true}
                        selectedId={currentSelectedId}
                        closeHandler={handleClose}
                        getApprovedPosition={getApprovedPosition}
                        currentSelectedOrder={currentSelectedOrder}
                    />
                )}

                {confirmationPopup && <ApprovalCaution handleClose={() => {
                    setConfirmationPopup(false)

                }}

                    onClose={() => {
                        setConfirmationPopup(false)
                        setAcceptAll(false)
                    }}
                    open={confirmationPopup}
                    popupValues={popupValues}
                    setAcceptAll={setAcceptAll}
                    acceptAll={acceptAll}
                    busy={busy}
                    toggleValue={approvedToggle}
                    handleSubmit={handlePopUp}
                />}
            </MROFullScreenRightDrawer>
        </div >
    )
}
