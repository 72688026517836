import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import MROButton from 'components/buttons';
import React, { useState, useReducer, useContext } from 'react'
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from 'material-table';
import tableIcons, {
    options,
} from "components/universal/table_attributes";
import tableReducer, {
    INITIAL_TABLE_STATE,
} from "reducers/table_reducer";
import { ToastMessageContext } from "lib/contexts/message_context";
import TableAction from "actions/table_actions";
import CreateRemovedParts from './CreateParts';
import { WorkOrderServices } from 'lib/services/api/operaitons/lineMaintenance/workOrder/workOrder';
import { useEffect } from 'react';
import { getOrganisationId } from 'lib/utils/common_utils';
import { Visibility as ViewIcon } from '@material-ui/icons';
import { BASE_API_URL_BOXR } from 'lib/constants';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ManageDataDialog from 'components/form_components/manage_data_dialog'
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';


function RemovedParts(props) {
    const [action, setAction] = useState('')
    const { section, getSectionName, setCreateSection, workOrderId, mroOwned, taskDetails } = props
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const [totalCount, setTotalCount] = useState(5)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    const [currentSelection, setCurrentSelection] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(5)
    const [loadingDelete, setloadingDelete] = useState(false)

    //table refresh
    const { tableRefresh, setTableRefresh } = useContext(WorkshopContext)
    // console.log("Refresh=======", mroOwned)
    const tableOptions = {
        ...options,
        page: page,
        pageSize: pageSize,
        totalCount: totalCount,
        search: false,
    };
    const handleClick = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(false);
        setAction(null);
        setCurrentSelection({});
    };
    const options1 = ["Delete", "Edit"];
    const ITEM_HEIGHT = 48;

    const handleOption = (option) => {
        if (option === "Delete") {
            setAction("delete");
        }
        else if (option === "Edit") {
            setAction("update");

        };

    }
    const handleDelete = async (e) => {
        setloadingDelete(true)
        const formData = {
            part_allocation_id: currentSelection.id
        }
        await WorkOrderServices.deleteRemovedParts(formData).then((res) => {
            if (res.success) {
                message.showToastMessage({ message: "Removed successfully", variant: "success" });
            }
            else {

                message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again", variant: "error" });
            }
            handleCloseMenu()
            getRemovedPartList()

        }).catch((res) => {
            handleCloseMenu()
            message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again", variant: "error" });
        }).finally(() => {
            setLoading(false)
        })
    }



    const columns = [
        {
            title: "Task #",
            field: "task",
            width: "10%",
            hidden: mroOwned !== "request_part" && "true",
            cellStyle: {
                textAlign: "left",
                width: "10%",
                paddingLeft: 10,
            },
            headerStyle: {
                width: "10%",
                textAlign: "left",
                paddingLeft: 10,
            },

        },
        {
            title: "Part #",
            field: "part_off",

            headerStyle: {
                textAlign: "left",
                paddingLeft: 15,

            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: 15,

            },
        },
        {
            title: "Serial #",
            field: "serial_off",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",

            },
        },
        {
            title: "Description",
            field: "description_off",
            width: "50%",
            headerStyle: {
                textAlign: "left",
                width: "50%"
            },
            cellStyle: {
                textAlign: "left",
                width: "50%"
            },
        },
        {
            title: "Quantity",
            field: "part_quantity",
            render: (rowData) => rowData.part_quantity || '-'
        },
        {
            title: "Label",
            field: "us_label",
            render: (rowData) => (
                <IconButton onClick={() => {
                    if (rowData.us_label != undefined) {
                        window.open(`${BASE_API_URL_BOXR}${rowData.us_label.url}`)
                    }

                }}>
                    <ViewIcon fontSize='small' />

                </IconButton>
            )
        },
        {
            title: "Action",
            field: "",
            render: (rowData) => (
                <div id={`div-${rowData.id}`}>
                    <IconButton
                        aria-label="more"
                        aria-controls={`long-menu-${rowData.id}`}
                        aria-haspopup="true"
                        onClick={(e) => {
                            setCurrentSelection(rowData);
                            handleClick(e);
                        }}
                    >
                        <MoreVertIcon />

                    </IconButton>
                </div>
            )
        },

    ]


    const getRemovedPartList = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            work_order_id: workOrderId,
            page: page + 1,
            count_per_page: pageSize

        }
        await WorkOrderServices.getRemovedParts(params).then((response) => {
            setData(response.data)
            setTotalCount(response.total_count)

        }).catch((response) => {
            message.showToastMessage({
                message: response?.message,
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
            setTableRefresh(false)
        })
    }
    useEffect(() => {
        const timeout = setTimeout(() => {
            getRemovedPartList()
        }, 0)
        return () => {
            clearTimeout(timeout)
        }

    }, [page, pageSize, tableRefresh])

    return (
        <div>

            <Box textAlign={"end"} mt={'-50px'}>
                <MROButton
                    variant="contained"
                    color="secondary"
                    disabled={!props?.props?.permission?.write}
                    onClick={() => {
                        setCreateSection(
                            getSectionName(section.sub_section_name)
                        );
                        setAction("create_removed_parts");
                        // setAction("create_alternative_part");
                    }}
                    startIcon={<AddIcon size="small" />}
                >
                    Removed  {getSectionName(section.sub_section_name)}
                </MROButton>
            </Box>
            <br />
            <Box>
                <MaterialTable style={{ boxShadow: "0px 1px 3px #00000033" }}
                    icons={tableIcons}
                    classes
                    title={""}
                    columns={columns}
                    isLoading={loading}
                    data={data ?? []}
                    options={tableOptions}

                    onChangePage={(page) => {
                        setPage(page)
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                        setPageSize(pageSize)
                    }}
                    totalCount={totalCount}
                    page={page} />
            </Box>
            <br />
            {open && <Menu
                id={`long-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleCloseMenu}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                    },
                }}
            >
                {currentSelection && options1.map((option) => {

                    return (
                        <MenuItem
                            // disabled={!props.permission.write && option !== "U/S label"}
                            key={option}
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                                handleOption(option);
                            }}
                        >
                            {option}
                        </MenuItem>
                    );
                })}



            </Menu>}
            {action && action === 'create_removed_parts' && <CreateRemovedParts handleClose={() => setAction(null)} workOrderId={workOrderId} getRemovedPartList={getRemovedPartList} action={action} mroOwned={mroOwned} taskDetails={taskDetails} />}

            {action && action === 'update' && <CreateRemovedParts handleClose={handleCloseMenu} workOrderId={workOrderId} getRemovedPartList={getRemovedPartList} currentSelection={currentSelection} action={action} mroOwned={mroOwned} taskDetails={taskDetails} />}
            {action === "delete" && (
                <ManageDataDialog
                    type={"delete"}
                    action={action}
                    handleClose={handleCloseMenu}
                    handleSubmit={handleDelete}
                // loading={loadingDelete}
                >
                    Are you sure? Confirm Delete.
                </ManageDataDialog>
            )}

        </div>
    )
}

export default React.memo(RemovedParts)