import React, { useContext, useEffect, useReducer, useState } from "react";
import RightDialog from "../../../../Stores/Parts/Stocks/RightDialog";
import { makeStyles } from "lib/utils/common_utils";
import { Box, IconButton, Typography, MenuItem } from "@material-ui/core";
import MROTextField from "components/form_components/TextField";
import MROButton from "components/buttons";
import DeleteIcon from "@material-ui/icons/Delete";
import { ICON_COLOUR } from "lib/constants/style_constants";
import QuantityCounter from "components/quantity_counter";
import { ToastMessageContext } from "lib/contexts/message_context";
import WarningIcon from "@material-ui/icons/Warning";
import { OperationService } from "lib/services/api/operaitons/save_operation";
import DataServiceAction from "actions/data_actions";
import ManageDataDialog from "components/form_components/manage_data_dialog";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DataService from "lib/services/api";
import dataReducer, { INITIAL_DATA_STATE } from "reducers/data_reducer";
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      minHeight: "89px",
    },
  },
  title: {
    fontSize: "15px",
    marginBottom: "16px",
    color: theme.palette.primary.main,
  },
}));

function EditPartDetails(props) {
  const {
    open,
    onClose,
    currentSelectedData,
    getList,
    dispatch,
    closeHandler,
    getPartDetials,
    taskDetails
  } = props;
  const classes = useStyles();
  const [qty, setQty] = useState();
  const message = useContext(ToastMessageContext);
  const [action, setAction] = useState(null);
  const [errors, setErrors] = useState({});
  const [busy, setBusy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [state, rDispatch] = useReducer(dataReducer, INITIAL_DATA_STATE);
  const { tableRefresh, setTableRefresh } = useContext(WorkshopContext)
  const [seletectedTask, setSelectedTask] = useState({})
  const [selectedTaskId, SetSelectedID] = useState({})
  const installField = [
    {
      label: "Task # *",
      placeholder: "Search & Select",
      type: "dropdown",
      id: selectedTaskId,
      setId: SetSelectedID,
      value: seletectedTask,
      setValue: setSelectedTask,
      // defaultValue: currentSelectedData?.data?.operational_task_id,
      required: true,
      name: "operational_task_id",
      values: taskDetails?.map((item) => ({
        id: item?.id,
        value: item?.task_tlp,
      })),
    },
    {
      label: "Comment ",
      placeholder: "comment",
      type: "text",
      required: false,
      name: "issued_comment",
      defaultValue: currentSelectedData?.data?.comment
    },
    {
      label: "Install Location ",
      placeholder: "Location",
      type: "text",
      required: false,
      name: "install_location",
      defaultValue: currentSelectedData?.part_condition_details?.install_location,
    },
    {
      label: "DD / HIL # ",
      placeholder: "DD / HIL reference",
      type: "text",
      required: false,
      name: "defferal_data",
      defaultValue: currentSelectedData?.part_condition_details?.defferal_data &&
        currentSelectedData?.part_condition_details?.defferal_data !== "null"
        ? currentSelectedData?.part_condition_details?.defferal_data
        : "",
    },
  ];
  const usLabelFields = [
    {
      label: "Part # Off *",
      placeholder: "Enter Part Number",
      type: "text",
      required: true,
      name: "part_off",
      defaultValue: currentSelectedData?.part_condition_details?.part_off,
    },
    {
      label: "Serial # Off *",
      placeholder: "Enter Serial Number",
      type: "text",
      required: true,
      name: "serial_off",
      defaultValue: currentSelectedData?.part_condition_details?.serial_off,
    },
    {
      label: "U/S Comment",
      placeholder:
        "Enter a removal reason or other comment as applicable - Max 150 Characters",
      type: "description",
      required: false,
      name: "issued_comment",
      defaultValue: currentSelectedData?.part_condition_details?.issued_comment,
    },
  ];

  useEffect(() => {
    if (currentSelectedData?.data?.required_quantity) {
      setQty(currentSelectedData?.data?.required_quantity);
    }
  }, [currentSelectedData?.data?.required_quantity]);





  useEffect(() => {
    if (taskDetails.length > 0) {
      if (currentSelectedData?.data?.operational_task_id !== undefined || null) {
        let value = taskDetails.find((item) => item.id === currentSelectedData?.data?.operational_task_id)

        setSelectedTask(value?.task_tlp)

        SetSelectedID(({
          id: value?.id,
          value: value?.task_tlp
        }))
      }
    }
  }, [currentSelectedData, taskDetails])



  useEffect(() => {
    if (qty < currentSelectedData.data?.allocated_quantity) {
      console.log("inside if check");
      setErrors((prevState) => ({
        ...prevState,
        quantity: "New value must NOT be less than request QTY allocated",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        quantity: false,
      }));
    }
  }, [qty, currentSelectedData.data?.allocated_quantity]);

  const validateFields = (formData) => {
    let valid = true;
    let error = {};

    const allFields = [...installField];

    allFields.forEach(({ required }) => {
      for (let pair of formData.entries()) {
        const key = pair[0];
        if (required === false) {
          error[key] = false;
          valid = true;
        } else if (pair[1] === "" && required === true) {
          error[key] = true;
          valid = false;
        }
      }
    });


    if (errors["quantity"]) {
      valid = false;
    }

    if (!valid) {
      setErrors((prevState) => ({ ...prevState, ...error }));
    }
    return valid;
  };

  // useEffect(() =>{
  //   getList();
  // },[currentSelectedData?.data?.id])

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("id", currentSelectedData?.data?.id);
    if (!validateFields(formData)) {
      return;
    }
    setBusy("loading");
    formData.delete("operational_task_id")
    formData.append("operational_task_id", selectedTaskId?.id)
    if (currentSelectedData?.data?.id) {
      await OperationService.editRequestPart(formData).then((res) => {
        if (res) {
          if (res.success) {
            getList();
            setBusy("");
            closeHandler();
            message.showToastMessage({
              message: "Updated successfully",
              variant: "success",
            });
            setTimeout(() => {
              setTableRefresh(true)
            }, 2000)

          } else {
            message.showToastMessage({
              message: res?.message,
              variant: "success",
            });
            setTimeout(() => {
              setTableRefresh(true)
            }, 2000)
          }
        }
      }).catch((err) => {
        message.showToastMessage({
          message: err?.message,
          variant: "success",
        });
      })
    }
  };

  const handleDelete = async () => {
    await DataServiceAction.deleteData(
      dispatch,
      message,
      "",
      { id: currentSelectedData?.data?.id },
      "destroyRequest",
      onClose,
      null,
      getList
    );
    closeHandler();
    setTimeout(() => {
      setTableRefresh(true)
    }, 2000)
  };

  useEffect(() => {
    console.table(currentSelectedData);
    console.log(
      "yes no field value",
      currentSelectedData?.part_condition_details?.rotable_removed
    );
    const defaultValue = currentSelectedData?.part_condition_details
      ?.rotable_removed
      ? "yes"
      : "no";
    setValue(defaultValue);
  }, [currentSelectedData?.part_condition_details]);

  const handleToggle = (value) => {
    if (value) {
      setValue(value);
    }
  };

  return (
    <RightDialog open={open} dialogCloseHandler={onClose}>
      <div className={classes.root}>
        <Box
          padding="23px 38px"
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#4D4F5C",
              marginBottom: "40px",
              fontWeight: "bold",
              fontSize: "17px",
            }}
          >
            Edit
          </Typography>
          <Typography color="primary" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Requested Part
          </Typography>

          <form noValidate onSubmit={submitHandler}>
            <QuantityCounter
              name={"quantity"}
              label={"Quantity Requested"}
              placeholder="QTY"
              required
              autoFocus={errors["quantity"]}
              error={errors["quantity"]}
              helperText={
                errors["quantity"] && typeof errors["quantity"] === "boolean"
                  ? `Please select Quantity Received`
                  : errors["quantity"] && errors["quantity"]
              }
              setError={() =>
                setErrors((prevState) => ({
                  ...prevState,
                  quantity: false,
                }))
              }
              setQty={setQty}
              qty={qty}
            />

            {installField.map(
              (
                { label, placeholder, type, required, name, defaultValue, values, value,
                  setValue,
                  setId, id },
                i
              ) => {
                switch (type) {
                  case "text":
                    return (
                      <MROTextField
                        key={i}
                        placeholder={placeholder}
                        label={label}
                        type={type}
                        name={name}
                        defaultValue={defaultValue}
                        autoFocus={required && errors[name]}
                        error={required && errors[name]}
                        helperText={
                          errors[name] &&
                          required &&
                          `Please select ${label.replace("*", "")}`
                        }
                        onChange={() =>
                          setErrors((prevState) => ({ ...prevState, [name]: false }))
                        }
                        variant="outlined"
                        color="primary"
                        InputLabelProps={{ shrink: true }}
                      />
                    )
                  case "dropdown":

                    return (
                      <Autocomplete
                        freeSolo
                        fullWidth
                        getOptionSelected={(option, value) => option.id === value.id}
                        filterSelectedOptions={true}
                        onChange={(event, value) => {
                          setId(value)
                        }}
                        id="tags-id"
                        options={values || []}
                        defaultValue={defaultValue}
                        getOptionLabel={(option) => option.value || ""}
                        onInputChange={(event, newInputValue) => {
                          setValue(newInputValue)

                        }}
                        value={id && id}
                        inputValue={value}
                        renderInput={(params) => (
                          <MROTextField
                            {...params}
                            // variant="standard"
                            label={label}
                            InputLabelProps={{ shrink: true }}
                            style={{ marginRight: "50px" }}
                            name={name}
                            color={"primary"}
                            placeholder={"Search & Select"}
                            error={errors[name]}
                            helperText={
                              errors[name] &&
                              required &&
                              `Please select ${label.replace("*", "")}`
                            }

                            onSelect={() => {
                              setErrors((prevState) => ({ ...prevState, [name]: false }))
                            }}
                          />
                        )}
                      />

                    );
                  default:
                    break;
                }
              }
            )}
            {/* {currentSelectedData?.defferal_data != "undefined" &&
              qty > currentSelectedData?.base_stock && (
                <>
                  <Box display="flex" alignItems="center">
                    <WarningIcon
                      style={{
                        color: "#FFB300",
                        marginRight: "10px",
                        fontSize: 20,
                      }}
                    />
                    Required QTY exceeds Base Stock QTY! - Please enter a
                    deferred defect number for tracking
                  </Box>
                  <MROTextField
                    placeholder="Deferral #"
                    label="ADD / Deferral # *"
                    name="defferal_data"
                    autoFocus={errors["defferal_data"]}
                    error={errors["defferal_data"]}
                    helperText={
                      errors["defferal_data"] && `Please enter Deferral`
                    }
                    onChange={() =>
                      setErrors((prevState) => ({
                        ...prevState,
                        defferal_data: false,
                      }))
                    }
                    variant="outlined"
                    color="primary"
                    InputLabelProps={{ shrink: true }}
                    // defaultValue={currentSelectedData["defferal_data"]}
                  />
                </>
              )} */}
            {/* 
            <Box mb="10px" mt="10px">
              <Box display="inline-block" mr={2}>
                <Typography gutterBottom={12} style={{ fontWeight: "bold" }}>
                  Repairable Part Removed? *
                </Typography>
              </Box>
              <input
                style={{ display: "none" }}
                name="rotable_removed"
                value={value === "no" ? false : true}
              />
              <ToggleButtonGroup
                value={value}
                name=""
                exclusive
                onChange={(e, val) => handleToggle(val)}
                aria-label="yes-or-no"
              >
                <ToggleButton
                  // className={
                  //   errors.part_removed && !errors?.part_removed?.valid
                  //     ? classes.error
                  //     : {}
                  // }
                  size={"small"}
                  value="no"
                  aria-label="centered"
                >
                  No
                </ToggleButton>
                <ToggleButton
                  // className={
                  //   errors.part_removed && !errors?.part_removed?.valid
                  //     ? classes.error
                  //     : {}
                  // }
                  size={"small"}
                  value="yes"
                  aria-label="left aligned"
                >
                  Yes
                </ToggleButton>
              </ToggleButtonGroup>
            </Box> */}

            {/* {value === "yes" && (
              <Typography color="primary" className={classes.title}>
                U/S Label
              </Typography>
            )}
            {usLabelFields.map(
              ({ label, placeholder, type, required, name, defaultValue }) =>
                value === "yes" && (
                  <MROTextField
                    placeholder={placeholder}
                    name={name}
                    label={label}
                    defaultValue={defaultValue}
                    variant="outlined"
                    multiline={type === "description"}
                    color="primary"
                    InputLabelProps={{ shrink: true }}
                    style={{ marginBottom: "10px" }}
                    autoFocus={errors[name]}
                    error={errors[name]}
                    helperText={
                      errors[name] &&
                      required &&
                      `Please select ${label.replace("*", "")}`
                    }
                    onChange={() =>
                      setErrors((prevState) => ({
                        ...prevState,
                        [name]: false,
                      }))
                    }
                  />
                )
            )} */}
            <div
              style={{
                marginTop: "30px",
                marginBottom: "60px",
                alignSelf: "flex-end",
              }}
            >
              <MROButton
                style={{ marginRight: "20px" }}
                type="button"
                variant={"contained"}
                onClick={closeHandler}
              >
                Cancel
              </MROButton>
              <MROButton
                type="submit"
                variant={"contained"}
                color="primary"
                loading={busy === "loading"}
              >
                Save
              </MROButton>
            </div>
          </form>

          <Box
            mt="auto"
            alignSelf="flex-end"
            display="flex"
            alignItems="center"
            onClick={() => setAction("delete")}
          >
            <Typography>Delete item</Typography>
            <IconButton>
              <DeleteIcon color={ICON_COLOUR} />
            </IconButton>
          </Box>
        </Box>

        {action === "delete" && (
          <ManageDataDialog
            loading={loading}
            type={"delete"}
            action={action}
            handleClose={closeHandler}
            handleSubmit={handleDelete}
          >
            Are you sure? Confirm Delete.
          </ManageDataDialog>
        )}
      </div>
    </RightDialog>
  );
}

export default EditPartDetails;
