import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControl,
  Select,
  InputBase,
  MenuItem,
  Box,
  IconButton,
  FormControlLabel,
  Switch,
  useTheme,
  InputAdornment,
} from "@material-ui/core";
import SearchBarWithFilters from "components/search_with_filters";
import useDependencies from "lib/hooks/useDependencies";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import MROTextField from "components/form_components/TextField";
import QuantityCounter from "components/quantity_counter";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import MROButton from "components/buttons";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import useDebounce from "lib/utils/debounce";
import { ToastMessageContext } from "lib/contexts/message_context";
import { Visibility } from "@material-ui/icons";
import WarningIcon from "@material-ui/icons/Warning";
import StockInfo from "../../../../../../Stores/Parts/Stocks/stockInfo";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { OperationService } from "lib/services/api/operaitons/save_operation";
import CopyParts from 'assets/copyparts.png'

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    "& .MuiOutlinedInput-multiline": {
      minHeight: "89px",
    },

    "& textarea::placeholder": {
      textTransform: "none !important",
    },

    "& form": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },

    "& .Mui-disabled": {
      color: "rgba(0, 0, 0, 0.7)",
    },
  },
  error: {
    borderColor: "#ff0056",
    borderWidth: "1px",
    borderStyle: "solid",
    margin: "0px",
  },
  title: {
    fontSize: "20px",
  },

  StockInfoDrawer: {
    "& > div[aria-hidden='true']": {
      visibility: "hidden !important",
    },
  },
}));

function Search(props) {
  const { baseStationId, lineCustomerId, acReg, workOrderId, onClose, taskDetails } = props;
  const classes = useStyles();
  const message = useContext(ToastMessageContext);
  const theme = useTheme();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, inProgress, data } = tableState;
  const [baseStations, lineCustomers] = useDependencies();
  const [searchText, setSearchText] = useState("");
  const debouncedVal = useDebounce(searchText);
  const [qty, setQty] = useState();
  const [value, setValue] = useState(null);
  const [currentSelection, setCurrentSelection] = useState({});
  const [action, setAction] = useState(null);
  const [errors, setErrors] = useState({});
  const [busy, setBusy] = useState(null);
  const [workOrderNum, setWorkOrderNum] = useState();
  const [filters, setFilters] = useState({
    line_customer_id: null,
    mro_owned: false,
  });
  const [selectedPart, setSelectedPart] = useState({});
  const [quantityRemoved, setQuantityRemoved] = useState();
  const [copyPartsOff, setCopyPartsOff] = useState('')
  const [seletectedTask, setSelectedTask] = useState({})
  const [selectedTaskId, SetSelectedID] = useState({})

  useEffect(() => {
    const myData = JSON.parse(localStorage.getItem("workOrder"));
    setWorkOrderNum(myData?.work_order_number);
  }, []);

  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      base_station_id: baseStationId,
    }));
  }, [baseStationId]);

  const getList = () => {
    const parmas = {
      search_keyword: debouncedVal,
      page: page + 1,
      count_per_page: pageSize,
      ...filters,
    };

    TableAction.getList(dispatch, message, parmas, "requestPart");
  };

  useEffect(() => {
    if (filters.hasOwnProperty("base_station_id")) {
      getList();
    }
  }, [page, debouncedVal, pageSize, filters]);

  const addPartFields = [
    {
      label: "Selected Part # *",
      placeholder: "No Part Selected",
      type: "text",
      required: true,
      name: "selected_part_number",
      disabled: true,
      defaultValue: selectedPart.part_number,
    },
    {
      label: "Base Stock QTY",
      placeholder: "No Part Selected",
      type: "text",
      required: false,
      name: "stock_qty",
      disabled: true,
      defaultValue: selectedPart.own_stock,
    },

  ];

  const addPartFields1 = [{
    label: "Task # *",
    placeholder: "Search & Select",
    type: "select",
    required: true,
    name: "operational_task_id",
    disabled: false,
    maxLength: "",
    setId: SetSelectedID,
    values: seletectedTask,
    setValues: setSelectedTask,
    data: taskDetails?.map((item) => ({
      id: item?.id,
      value: item?.task_tlp,
    })),
  },
  {
    label: "Comment *",
    placeholder: "E.g., ZONE",
    type: "text",
    required: true,
    name: "issued_comment",
    disabled: false,
    data: "",
    maxLength: 30
  },
  {
    label: value === "yes" ? "Install Location *" : "Install Location",
    placeholder: "Location",
    type: "text",
    required: value === "yes" ? true : false,
    name: "install_location",
    disabled: false,
    data: "",
    maxLength: 30
  },
  {
    label: "DD / HIL ",
    placeholder: "DD / HIL reference",
    type: "text",
    required: false,
    name: "defferal_data",
    disabled: false,
    data: "",
    maxLength: 30
  },]

  const repairableFields = [
    {
      label: "Part # Off *",
      placeholder: "Enter Part Number",
      type: "text",
      required: true,
      name: "part_off",
      disabled: false,
    },
    {
      label: "Serial # Off *",
      placeholder: "Enter Serial Number",
      type: "text",
      required: true,
      name: "serial_off",
      disabled: false,
    },
    {
      label: "U/S Comment * ",
      placeholder:
        "Enter a removal reason or other comment as applicable - Max 150 Characters",
      type: "description",
      required: true,
      name: "us_comment",
      disabled: false,
    },
  ];

  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    pageSize: 10,
    searchText: searchText,
  };

  const handleViewInfo = (rowData) => {
    setAction("view");
    setCurrentSelection(rowData);
  };

  const columns = [
    {
      title: "Part #",
      field: "part_number",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "10px",
        width: "35%",
      },
      headerStyle: {
        textAlign: "left",
        paddingLeft: "10px",
        width: "35%",
      },
    },
    {
      title: "Description",
      field: "description",
      cellStyle: {
        width: "40%",
        paddingLeft: "20px",
      },
      headerStyle: {
        width: "40%",
        textAlign: "left",
        paddingLeft: "20px",
      },
    },
    {
      title: "Total Stock",
      field: "total_stock",
    },
    {
      title: "Base Stock",
      field: "own_stock",
    },

    {
      title: "Stock Info",
      field: "",
      headerStyle: {
        textAlign: "center",
        width: "6%",
      },
      cellStyle: {
        textAlign: "center",
        width: "6%",
      },
      render: (rowData) => (
        <IconButton onClick={() => handleViewInfo(rowData)}>
          <Visibility fontSize="small" htmlColor={ICON_COLOUR} />
        </IconButton>
      ),
    },
    {
      title: "Select",
      field: "select",
      render: (rowData) => (
        <IconButton onClick={() => setSelectedPart(rowData)}>
          <TouchAppIcon />
        </IconButton>
      ),
    },
  ];

  const handleToggle = (value) => {
    if (value) {
      setValue(value);
    }
    if (value === "no") {
      setErrors({ ...errors, install_location: false, part_off: false, serial_off: false, us_comment: false, removed_quantity: false });
    }

  };

  const handleCopyFunction = () => {
    setCopyPartsOff(selectedPart.part_number)

  }
  useEffect(() => {
    if (qty > selectedPart.own_stock) {
      console.log("inside if check");
      setErrors((prevState) => ({
        ...prevState,
        quantity:
          "Required QTY exceeds Base Stock QTY! - Please enter a deferred defect number for tracking",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        quantity: false,
      }));
    }
  }, [qty, selectedPart.own_stock]);

  const validateFields = (formData) => {
    let valid = true;
    let error = {};

    const allFields = [...addPartFields, ...addPartFields1, ...repairableFields,];

    const required_quantity = formData.get("quantity");
    const location = formData.get("install_location")

    allFields.forEach(({ required, disabled }) => {
      for (let pair of formData.entries()) {
        const key = pair[0];

        if (pair[1] === "" && required === true) {
          if (key === "defferal_data" || key === "stock_qty" || key === "selected_part_number" || key === "install_location") {
            //do nothing
          }
          else {
            error[key] = true;
            valid = false;
          }
        }
      }
    });
    if ((value === "yes" && location === "")) {
      error["install_location"] = true;
      valid = false;
    }
    console.log("errors====", error)
    if (!value) {
      error["rotable_removed"] = true;
      valid = false;
    }

    if (+required_quantity === 0) {
      error["quantity"] = true;
      error["required_quantity_is_zero"] = true;
      valid = false;
    }

    if (!valid) {
      setErrors((prevState) => ({ ...prevState, ...error }));
    }
    return valid;
  };

  useEffect(() => {
    console.log({ selectedPart });
  }, [selectedPart]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.delete("rotable_removed");
    formData.append("rotable_removed", value === "yes" ? true : false);
    formData.append("to_base_station_id", baseStationId);
    formData.append("line_customer_id", lineCustomerId);
    formData.append("user_id", user?.id);
    formData.append("aircraft_registration", acReg);
    formData.append("primary_part_id", selectedPart.id);
    formData.append("reportable_type", "WorkOrder");
    formData.append("reportable_id", workOrderId);
    formData.append("order_number", workOrderNum);

    if (!validateFields(formData)) {
      return;
    }
    formData.delete("operational_task_id")
    formData.append("operational_task_id", selectedTaskId?.id)
    setBusy("loading");
    try {
      const response = await OperationService.addRequestPart(formData);
      console.log("response", response);
      if (response.success) {
        message.showToastMessage({
          message: "Added successfully.",
          variant: "success",
        });
      }
      else {
        message.showToastMessage({ message: response.errors ? response.errors.join(', ') : "Something went wrong. Try again", variant: "error" });
      }
      onClose();


      setBusy(null);
    } catch (res) {
      message.showToastMessage({ message: res.errors ? res.errors.join(', ') : "Something went wrong. Try again", variant: "error" });
      setBusy("error");
    }

    console.log("Validation passeed");
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={8}>
          <Typography color="primary" className={classes.title}>
            Search Part
          </Typography>
          <SearchBarWithFilters
            handleSearch={(value) => {
              TableAction.setPage(dispatch, 0);
              setSearchText(value);
            }}
            searchText={searchText}
          >
            <FormControl size="medium" className={"filter"}>
              <Select
                fullWidth
                displayEmpty
                defaultValue={baseStationId}
                value={filters.base_station_id}
                onChange={(e) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    base_station_id: e.target.value,
                  }))
                }
                input={<InputBase />}
              >
                <MenuItem value={""}>Base (All)</MenuItem>
                {baseStations?.map((item, i) => (
                  <MenuItem key={i} value={item.base_station_id}>
                    {item.station}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="medium" className={"filter"}>
              <Select
                fullWidth
                displayEmpty
                value={filters.line_customer_id}
                defaultValue={null}
                onChange={(e) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    line_customer_id: e.target.value,
                    mro_owned: false,
                  }))
                }
                input={<InputBase />}
              // disabled={filters.mro_owned}
              >
                <MenuItem value={null}>Owner (All)</MenuItem>
                {lineCustomers?.map((item, i) => (
                  <MenuItem key={i} value={item.line_customer_id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              style={{ marginTop: "-7.5px" }}
              control={
                <Switch
                  checked={filters?.mro_owned}
                  onChange={(e) =>
                    setFilters((prevState) => ({
                      ...prevState,
                      mro_owned: e.target.checked,
                      line_customer_id: null,
                    }))
                  }
                  // name="checkedB"
                  color="primary"
                />
              }
              label="MRO Owned Part?"
              labelPlacement="start"
            />
          </SearchBarWithFilters>

          {/* Table */}
          <MaterialTable
            style={{ marginTop: 20 }}
            columns={columns}
            icons={tableIcons}
            title=""
            data={data.data || []}
            isLoading={inProgress}
            options={tableOptions}
            onChangePage={(page) => {
              TableAction.setPage(dispatch, page);
            }}
            onChangeRowsPerPage={(pageSize) => {
              TableAction.setPageSize(dispatch, pageSize);
            }}
            onSearchChange={(search) => {
              TableAction.setSearchText(dispatch, search);
            }}
            totalCount={totalCount}
            page={page}
          />
        </Grid>
        <Grid item xs={4} style={{ paddingLeft: "50px" }}>
          <Typography color="primary" className={classes.title}>
            Add Part
          </Typography>

          <form noValidate onSubmit={submitHandler}>
            {addPartFields.map(
              (
                { placeholder, label, name, disabled, required, defaultValue },
                i
              ) => (
                <MROTextField
                  key={i}
                  placeholder={placeholder}
                  label={label}
                  name={name}
                  autoFocus={errors[name]}
                  error={errors[name]}
                  helperText={
                    errors[name] &&
                    required &&
                    `Please select ${label.replace("*", "")}`
                  }
                  onChange={() =>
                    setErrors((prevState) => ({ ...prevState, [name]: false }))
                  }
                  variant="outlined"
                  color="primary"
                  InputLabelProps={{ shrink: true }}
                  disabled={disabled}
                  value={disabled ? defaultValue ?? placeholder : undefined} // this condition added here to get rid of controlled/uncontolled error.
                />
              )
            )}
            <QuantityCounter
              name={"quantity"}
              label={"QTY Required"}
              placeholder="QTY"
              required
              autoFocus={errors["quantity"]}
              error={errors["quantity"] === true}
              helperText={
                errors["quantity"] &&
                typeof errors["quantity"] === "boolean" &&
                (errors["required_quantity_is_zero"]
                  ? "QTY Required Must Be Greater Than 0"
                  : `Please select Quanityt Received`)
              }
              shouldNotAcceptZero={true}
              setError={() =>
                setErrors((prevState) => ({
                  ...prevState,
                  quantity: false,
                  required_quantity_is_zero: false
                }))
              }
              setQty={setQty}
              qty={qty}
            />



            {/* {errors["quantity"] && typeof errors["quantity"] === "string" && (
              <>
                <Box display="flex" alignItems="center">
                  <WarningIcon
                    style={{
                      color: "#FFB300",
                      marginRight: "10px",
                      fontSize: 20,
                    }}
                  />
                  Required QTY exceeds Base Stock QTY! - Please enter a deferred
                  defect number for tracking
                </Box>
                <MROTextField
                  placeholder="DD / HIL reference"
                  label="DD / HIL # *"
                  name="defferal_data"
                  autoFocus={errors["defferal_data"]}
                  error={errors["defferal_data"]}
                  helperText={
                    errors["defferal_data"] && `Please enter Deferral`
                  }
                  onChange={() =>
                    setErrors((prevState) => ({
                      ...prevState,
                      defferal_data: false,
                    }))
                  }
                  variant="outlined"
                  color="primary"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={currentSelection["defferal_data"]}
                />
              </>
            )} */}

            {addPartFields1.map(
              (
                { placeholder, label, name, disabled, required, defaultValue, type, data, maxLength, setId, values, setValues },
                i
              ) =>
                type === "select" ?

                  <Autocomplete
                    freeSolo
                    fullWidth
                    getOptionSelected={(option, value) => option.id === value.id}
                    filterSelectedOptions={true}
                    onChange={(event, value) => {
                      setId(value)
                    }}
                    id="tags-id"
                    options={data || []}
                    // defaultValue={defaultValue}
                    getOptionLabel={(option) => option.value || ""}
                    onInputChange={(event, newInputValue) => {
                      setValues(newInputValue)

                    }}
                    inputValue={values}
                    renderInput={(params) => (
                      <MROTextField
                        {...params}
                        // variant="standard"
                        label={label}
                        InputLabelProps={{ shrink: true }}
                        style={{ marginRight: "50px" }}
                        name={name}
                        color={"primary"}
                        placeholder={"Search & Select"}
                        error={errors[name]}
                        helperText={
                          errors[name] &&
                          required &&
                          `Please select ${label.replace("*", "")}`
                        }

                        onSelect={() => {
                          setErrors((prevState) => ({ ...prevState, [name]: false }))
                        }}
                      />
                    )}
                  />

                  : <MROTextField
                    key={i}
                    placeholder={placeholder}
                    label={label}
                    name={name}
                    autoFocus={errors[name]}
                    error={errors[name] && required}
                    helperText={
                      errors[name] &&
                      required &&
                      `Please enter ${label.replace("*", "")}`
                    }
                    onChange={() =>
                      setErrors((prevState) => ({ ...prevState, [name]: false }))
                    }
                    type
                    variant="outlined"
                    color="primary"
                    InputLabelProps={{ shrink: true }}
                    disabled={disabled}
                    inputProps={{ maxLength: maxLength && maxLength }}
                    value={disabled ? defaultValue ?? placeholder : undefined} // this condition added here to get rid of controlled/uncontolled error.
                  />


            )}


            <Box mb="10px" mt="10px">
              <Box display="inline-block" mr={2}>
                <Typography gutterBottom={12} >
                  Repairable Part Removed? *
                </Typography>
              </Box>
              <input
                style={{ display: "none" }}
                name="rotable_removed"
                value={value}
              />
              <ToggleButtonGroup
                value={value}
                name="rotable_removed"
                exclusive
                onChange={(e, val) => {
                  handleToggle(val);
                  setErrors({ ...errors, rotable_removed: false });
                }}
                aria-label="yes-or-no"
                helperText={
                  errors["rotable_removed"] &&
                  `Please enter Units Issued Each QTY`
                }
              >
                <ToggleButton
                  className={errors?.rotable_removed ? classes.error : {}}
                  color={errors?.rotable_removed ? "red" : "primary"}
                  size={"small"}
                  value="no"
                  aria-label="centered"
                >
                  No
                </ToggleButton>
                <ToggleButton
                  className={errors?.rotable_removed ? classes.error : {}}
                  color={errors?.rotable_removed ? "red" : "primary"}
                  size={"small"}
                  value="yes"
                  aria-label="left aligned"
                >
                  Yes
                </ToggleButton>
              </ToggleButtonGroup>
              <br></br>
              {errors?.rotable_removed && !value ? (
                <span style={{ color: "red", marginTop: "10px", fontSize: "13px" }}>
                  Please select Yes or No
                </span>
              ) : (
                ""
              )}
            </Box>
            {value === "yes" && (
              <>
                <MROTextField
                  name="part_off"
                  error={errors?.part_off && !errors?.part_off?.valid}
                  label="Part # Off *"
                  variant="outlined"
                  value={copyPartsOff}
                  InputLabelProps={{ shrink: true }}
                  placeholder={"Enter Part Number"}
                  onChange={(e) => {
                    setCopyPartsOff(e.target.value)
                    setErrors((prevState) => ({
                      ...prevState,
                      part_off: false,
                    }))
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleCopyFunction}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <img src={CopyParts} height={'22px'} />
                      </IconButton>
                    </InputAdornment>,

                  }}

                />
                <MROTextField
                  name="serial_off"
                  error={errors?.serial_off && !errors?.serial_off?.valid}
                  variant="outlined"
                  label="Serial # Off *"
                  InputLabelProps={{ shrink: true }}
                  placeholder={"Enter Serial Number"}
                  onChange={() =>
                    setErrors((prevState) => ({
                      ...prevState,
                      serial_off: false,
                    }))
                  }
                />
                <QuantityCounter
                  name={"removed_quantity"}
                  label={"QTY Removed"}
                  placeholder="Quantity"
                  required
                  autoFocus={errors["removed_quantity"]}
                  error={errors["removed_quantity"] === true}
                  helperText={
                    errors["removed_quantity"] &&
                    typeof errors["removed_quantity"] === "boolean" &&
                    (errors["required_quantity_is_zero"]
                      ? "QTY Required Must Be Greater Than 0"
                      : `Please select Quantity Removed`)
                  }
                  shouldNotAcceptZero={true}
                  setError={() =>
                    setErrors((prevState) => ({
                      ...prevState,
                      removed_quantity: false,
                      required_quantity_is_zero: false
                    }))
                  }
                  setQty={setQuantityRemoved}
                  qty={quantityRemoved}
                />
                <MROTextField
                  name={"us_comment"}
                  label={"U/S Comment *"}
                  multiline
                  error={errors?.us_comment && !errors?.us_comment?.valid}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter a removal reason or other comment as applicable - Max 150 Characters"

                  onChange={() =>
                    setErrors((prevState) => ({
                      ...prevState,
                      us_comment: false,
                    }))
                  }
                />

              </>



            )}

            {/* {value === "yes" &&
              repairableFields.map(
                ({ placeholder, name, label, type, required }, i) => (
                  <MROTextField
                    placeholder={placeholder}
                    name={name}
                    label={label}
                    variant="outlined"
                    multiline={type === "description"}
                    color="primary"
                    InputLabelProps={{ shrink: true }}
                    style={{ marginBottom: "10px" }}
                    autoFocus={errors[name]}
                    error={errors[name]}
                    helperText={
                      errors[name] &&
                      required &&
                      `Please select ${label.replace("*", "")}`
                    }
                    onChange={() =>
                      setErrors((prevState) => ({
                        ...prevState,
                        [name]: false,
                      }))
                    }
                  />
                )
              )} */}

            <Box mt="auto" display="flex" justifyContent="flex-end" mb="20px">
              <MROButton
                style={{ marginRight: "20px" }}
                type="button"
                variant={"contained"}
                onClick={onClose}
              >
                Cancel
              </MROButton>
              <MROButton
                type="submit"
                variant={"contained"}
                style={{
                  backgroundColor:
                    selectedPart.part_number || selectedPart.own_stock
                      ? theme.palette.primary.main
                      : "#FFB300",
                  color: "#fff",
                }}
                // color="primary"
                loading={busy === "loading"}
              >
                Add
              </MROButton>
            </Box>
          </form>
        </Grid>
      </Grid >
      {action === "view" && currentSelection && (
        <StockInfo
          hideEdit={true}
          showSelectPartBtn
          onSelectPartBtnClick={(partDetails) => {
            setSelectedPart({
              own_stock: currentSelection.own_stock,
              id: currentSelection.id,
              ...partDetails,
            });
            setAction(null);
          }}
          permission={{ write: true }}
          action={action}
          currentSelection={currentSelection}
          handleClose={() => setAction(null)}
          className={classes.StockInfoDrawer}
        />
      )
      }
    </>
  );
}

export default Search;
