import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
  useMemo,
} from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TableAction from "../../../../actions/table_actions";
import tableIcons, {
  options,
} from "../../../../components/universal/table_attributes";
import { ToastMessageContext } from "../../../../lib/contexts/message_context";
import DataService from "../../../../lib/services/api";
import tableReducer, {
  INITIAL_TABLE_STATE,
} from "../../../../reducers/table_reducer";
import DataServiceAction from "../../../../actions/data_actions";
import ViewPrintUSLabel from "./ViewPrintUSLabel";
import ManageDataDialog from "../../../../components/form_components/manage_data_dialog";
import { BASE_API_URL_BOXR } from "../../../../lib/constants";
import AddNewPartsAndTools from "./add_parts_tools";
import LineCustomerServiceNew from "lib/services/api/admin/line/line_customers_client";
import { PartDetailsTableRefresh } from "./section_detail";
import ViewIcon from "@material-ui/icons/Visibility";
import AlternativePartsTable from "./AlternativeParts/AlternativePartsTable";
import {
  MRO_MANAGED_PART,
  MRO_NON_MANAGED_PART,
  MRO_REQUEST_PART,
} from "../../../../constants";
import LoadingIndicator from "components/universal/loading_indicator";

// const options1 = ["Delete", "U/S label", "View material certificate", "Edit"];
const options1 = ["Delete", "Edit"];
const ITEM_HEIGHT = 48;

export default function PartDetails(props) {
  const [dataa, setData] = useState([]);
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, searchText, inProgress, data, taskDetails } =
    tableState;
  const message = useContext(ToastMessageContext);
  const [currentSelection, setCurrentSelection] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [createSection, setCreateSection] = useState("");
  const [busy, setBusy] = useState(null);
  const { workOrderId, lineCustomerId, test, mroOwned, setMroOwned } = props;
  const RefreshPartDetailsTable = useContext(PartDetailsTableRefresh);

  useEffect(() => {
    setBusy("loading");
    LineCustomerServiceNew.getLineCustomerDetails({
      line_customer_id: lineCustomerId,
    })
      .then((res) => {
        const {
          line_customer: { part_type },
        } = res;
        setMroOwned(part_type);

        setBusy(null);
      })
      .catch((err) => {
        setBusy("error");
        console.log("something went wrong from part details", err);
      });
  }, []);

  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    pageSize: pageSize,
    searchText: searchText,
  };

  function getList() {
    let params = {
      // reportable_id: workOrderId,
      page: page + 1,
      count_per_page: pageSize,
      // reportable_type: "WorkOrder",
    };
    const key1 = mroOwned === MRO_MANAGED_PART ? "reportable_id" : "id";
    const key2 = mroOwned === MRO_MANAGED_PART ? "reportable_type" : "type";
    params[key1] = workOrderId;
    params[key2] = "WorkOrder";

    const url =
      mroOwned === MRO_MANAGED_PART ? "partsAndToolsRequestParts" : "vendorParts";
    // TableAction.getList(dispatch, message, params, "vendorParts");
    TableAction.getList(dispatch, message, params, url);
  }

  useEffect(() => {
    console.log({ mroOwned });
    if (props.hasOwnProperty("getList") && mroOwned !== 1) props.getList(getList);
  }, [props.getList]);

  useEffect(() => {
    if (
      (mroOwned === MRO_NON_MANAGED_PART) &&
      workOrderId &&
      mroOwned !== 1
    ) {
      getList();
    }
  }, [
    pageSize,
    page,
    searchText,
    totalCount,
    props?.createdId,
    mroOwned,
    RefreshPartDetailsTable,
  ]);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
    setAction(null);
    setCurrentSelection({});
  };

  const handleOption = (option) => {
    if (option === "Delete") {
      setAction("delete");
    } else if (option === "U/S label") {
      setAction("view");
    } else if (option === "View material certificate") {
      message.showToastMessage({
        open: true,
        message: "No certificate found!",
        variant: "error",
      });
    } else if (option === "Edit") {
      setAction("update");
    }
  };

  const checkRotable = (rowData) => {
    const { part_off, serial_off, removal_location } = rowData;
    if (part_off && serial_off && removal_location) {
      return <Typography style={{ color: "green" }}>On</Typography>;
    } else {
      return <Typography color="error">Off</Typography>;
    }
  };

  const checkRotableBoolean = (rowData) => {
    const { part_off, serial_off, removal_location } = rowData;
    if (part_off && serial_off && removal_location) {
      return true;
    } else {
      return false;
    }
  };

  const deleteMangedPart = (id) => {
    const formData = new FormData();
    formData.append("id", id);

    DataService.updateData(formData, "", "deleteMangedPart")
      .then((res) => {
        handleCloseMenu();
        if (res.success) {
          message.showToastMessage({
            open: true,
            message: "Deleted successfully",
            variant: "success",
          });
          getList();
        }
      })
      .catch((err) => {
        message.showToastMessage({
          open: true,
          message: "Something went wrong. Try Again",
          variant: "error",
        });
      });
  };

  const handleDelete = () => {
    let data = {
      vendor_part_id: currentSelection.vendor_part_id,
    };
    mroOwned === MRO_MANAGED_PART
      ? deleteMangedPart(currentSelection?.id)
      : DataServiceAction.deleteData(
        dispatch,
        message,
        null,
        data,
        "vendorParts",
        handleCloseMenu,
        null,
        getList
      );
  };

  const columns = [
    {
      title: "",
      field: "",
      headerStyle: {
        textAlign: "left",
        width: "1%",
      },
      cellStyle: {
        width: "0.8%",
      },
    },
    {
      title: "Part #",
      field: mroOwned === MRO_MANAGED_PART ? "part_number" : "part_on",
      headerStyle: {
        textAlign: "left",
        width: "10%",
      },
      cellStyle: {
        width: "10%",
      },
    },
    {
      title: "Batch",
      field: mroOwned === MRO_MANAGED_PART ? "batch_number" : "batch",
      headerStyle: {
        textAlign: "left",
        width: "15%",
      },
      cellStyle: {
        width: "15%",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
        width: "20%",
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      title: "Quantity",
      field: "quantity",
    },

    {
      title: "Serial #",
      field: mroOwned === MRO_MANAGED_PART ? "serial_number" : "serial_on",
      headerStyle: {
        textAlign: "left",
        width: "10%",
      },
      cellStyle: {
        width: "10%",
      },
    },
    {
      title: "Rotable off?",
      field: "",
      render: checkRotable,
      headerStyle: {
        textAlign: "left",
        width: "15%",
      },
      cellStyle: {
        width: "15%",
      },
    },
    {
      title: "",
      field: "id",
      headerStyle: {
        display: "none",
      },
      cellStyle: {
        display: "none",
      },
    },
    {
      title: "Action",
      field: "",
      render: (rowData) => (
        <div id={`div-${rowData.id}`}>
          <IconButton
            aria-label="more"
            aria-controls={`long-menu-${rowData.id}`}
            aria-haspopup="true"
            onClick={(e) => {
              setCurrentSelection(rowData);
              handleClick(e);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      ),
      headerStyle: {
        textAlign: "left",
        width: "7%",
      },
      cellStyle: {
        width: "7%",
      },
    },

    // {
    //   title: "test",
    //   field: "",
    //   render: (rowData) => (
    //     <IconButton onClick={() => setAction("partDetails")}>
    //       <ViewIcon />
    //     </IconButton>
    //   ),
    // },
  ];

  const handlePrintClose = () => {
    setAction(null);
    setOpen(false);
  };

  const viewDoc = (doc) => {
    doc != null
      ? window.open(`${BASE_API_URL_BOXR}${doc}`, "_blank")
      : message.showToastMessage({
        open: true,
        message: "No document found",
        variant: "error",
      });
  };

  const handleClose = () => {
    setCreateSection(null);
    setAction(null);
    setOpen(false);
  };

  if (busy === "loading") {
    return (
      <div style={{ height: "40vh" }}>
        <LoadingIndicator variant={"contained"} />
      </div>
    );
  }

  if (busy === "error") {
    return (
      <div style={{ height: "40vh", marginTop: "40px" }}>
        <Typography variant="h4" color="error" align="center">
          Something went wrong! Please try again later
        </Typography>
      </div>
    );
  }

  if (mroOwned === MRO_REQUEST_PART || mroOwned === MRO_MANAGED_PART) {
    return <AlternativePartsTable workOrderId={workOrderId} permission={props?.permission} mroOwned={mroOwned} taskDetails={taskDetails} />;
  }

  return (
    <>
      <Box>
        <MaterialTable
          columns={columns}
          icons={tableIcons}
          title=""
          data={data.data}
          isLoading={inProgress}
          options={tableOptions}
          onChangePage={(page) => {
            TableAction.setPage(dispatch, page);
          }}
          onChangeRowsPerPage={(pageSize) => {
            TableAction.setPageSize(dispatch, pageSize);
          }}
          onSearchChange={(search) => {
            TableAction.setSearchText(dispatch, search);
          }}
          totalCount={totalCount}
          page={page}
        />
      </Box>
      {open && (
        <Menu
          id={`long-menu`}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {!currentSelection?.certificate &&
            checkRotableBoolean(currentSelection) &&
            options1.map((option) => {
              if (option !== "View material certificate")
                return (
                  <MenuItem
                    disabled={!props.permission.write && option !== "U/S label"}
                    key={option}
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      handleOption(option);
                    }}
                  >
                    {option}
                  </MenuItem>
                );
            })}


          {/* <MenuItem
            style={{ fontSize: "12px" }}
            onClick={() => {
              viewDoc(currentSelection?.certificates[0]?.url ?? null);
            }}
          >
            Part Cert
          </MenuItem> */}


          {!currentSelection?.certificate &&
            !checkRotableBoolean(currentSelection) && (
              <MenuItem
                disabled={!props.permission.write}
                key={"Delete"}
                style={{ fontSize: "12px" }}
                onClick={() => {
                  handleOption("Delete");
                }}
              >
                Delete
              </MenuItem>
            )}
          {currentSelection.certificate &&
            !checkRotableBoolean(currentSelection) &&
            options1.map((option) => {
              if (option !== "U/S label")
                return (
                  <MenuItem
                    disabled={!props.permission.write && option !== "View material certificate"}
                    key={option}
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      handleOption(option);
                    }}
                  >
                    {option}
                  </MenuItem>
                );
            })}
          {!currentSelection.certificate &&
            !checkRotableBoolean(currentSelection) &&
            options1.map((option) => {
              if (option === "Edit")
                return (
                  <MenuItem
                    disabled={!props.permission.write}
                    key={option}
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      handleOption(option);
                    }}
                  >
                    {option}
                  </MenuItem>
                );
            })}
          {/*{*/}
          {/*    currentSelection.certificate && checkRotableBoolean(currentSelection) && options1.map((option) => {*/}
          {/*        return <MenuItem key={option} style={{fontSize: '12px'}} onClick={() => {*/}
          {/*            handleOption(option);*/}
          {/*        }}>*/}
          {/*            {option}*/}
          {/*        </MenuItem>*/}
          {/*    })*/}

          {/*}*/}
        </Menu>
      )}

      {/*{*/}
      {/*    action === 'view' && <ViewPrintUSLabel data={props.data} handlePrintClose={handlePrintClose}*/}
      {/*                                           currentSelection={currentSelection}/>*/}
      {/*}*/}

      {action === "view" && viewDoc(currentSelection?.document_url)}

      {action === "delete" && (
        <ManageDataDialog
          type={"delete"}
          action={action}
          handleClose={handleCloseMenu}
          handleSubmit={handleDelete}
        >
          Are you sure? Confirm Delete.
        </ManageDataDialog>
      )}
      {action === "update" && (
        <AddNewPartsAndTools
          action={action}
          getList={getList}
          handleClose={handleClose}
          workOrderId={workOrderId}
          currentSelection={currentSelection}
          lineCustomerId={lineCustomerId}
          createSection="part"
        />
      )}
    </>
  );
}
